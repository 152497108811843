import { Button } from "@mui/material";
import React from "react";
import Loader from "shared/loader/loader";
import styles from "./style.module.scss";

function index({ handleClick, text, loader }) {
  return (
    <div>
      <Button variant="contained" onClick={() => handleClick()} className={!loader ? styles.button : `${styles.button} ${styles.buttonPress}`}>
        {loader ? <Loader /> : <span className={styles.label}>{text}</span>}
      </Button>
    </div>
  );
}

export default index;
