import {
  Avatar,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Pagination } from "@mui/material";
import styles from "./style.module.scss";
import CloseIcon from "@mui/icons-material/Close";
import SkeletonLoader from "shared/skeletonLoader";
import { getDateFeebacks } from "services/dateFeedbackService";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Feedback from "shared/modals/feedback";
import { PICTURE } from "utils/constant";
import { ImagePlaceholder2, NoData } from "assets";

function DatesFeedback() {
  const [openDetails, setOpenDetails] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [search, setSearch] = useState("");
  const [feedbacks, setFeedbacks] = useState([]);
  const [loader, setLoader] = useState(false);
  const [active, setActive] = useState(1);
  const [count, setCount] = useState(null);

  const getAllFeedbacks = () => {
    setLoader(true);
    let obj = {
      user: search,
      page: active,
      status: 2,
    };
    getDateFeebacks(obj)
      .then(({ data }) => {
        setLoader(false);
        setCount(data?.data?.meta?.total);
        setFeedbacks(data?.data?.data);
      })
      .catch((err) => {
        console.log("err", err);
        setLoader(false);
      });
  };

  useEffect(() => {
    getAllFeedbacks();
  }, [search, active]);

  const handleOpenDetails = (item) => {
    setSelectedItem(item);
    setOpenDetails(true);
  };

  const handleCloseDetail = () => {
    setOpenDetails(false);
    setSelectedItem(null);
  };

  const handleChangePage = (event, newPage) => {
    setActive(newPage);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  return (
    <div>
      <Typography className={styles.title}>Dates feedback</Typography>
      <div
        style={{ display: "flex", alignItems: "center", marginBottom: "2%" }}
      >
        <div style={{ width: "100%", position: "relative" }}>
          <TextField
            InputProps={{
              style: {
                borderRadius: "12px",
                border: "none",
                backgroundColor: "white",
                height: "56px",
              },
            }}
            className={styles.input}
            sx={{ width: "100%" }}
            onChange={(e) => setSearch(e.target.value)}
            value={search}
            placeholder={`Search Feedback`}
          />
          {search.length > 0 ? (
            <div
              style={{
                position: "absolute",
                right: "15px",
                top: "15px",
                cursor: "pointer",
              }}
              onClick={() => setSearch("")}
            >
              <CloseIcon />
            </div>
          ) : null}
        </div>
      </div>

      {feedbacks?.length ? (
        <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ paddingLeft: "50px" }}>Sr.</TableCell>
                <TableCell>Request sender</TableCell>
                <TableCell>Request reciever</TableCell>
                <TableCell>Date location</TableCell>
                <TableCell>Feeback</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loader
                ? Array.from({ length: 8 }).map((_, index) => {
                    return (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell>
                          <div
                            className={styles.grid}
                            style={{ paddingLeft: "30px" }}
                          >
                            <SkeletonLoader style={styles.srSkeletonLoader} />
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className={styles.grid}>
                            <SkeletonLoader style={styles.skeletonLoader} />
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className={styles.grid}>
                            <SkeletonLoader style={styles.skeletonLoader} />
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className={styles.grid}>
                            <SkeletonLoader style={styles.skeletonLoader} />
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className={styles.grid}>
                            <SkeletonLoader style={styles.srSkeletonLoader} />
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })
                : feedbacks?.map((item, index) => {
                    return (
                      <TableRow>
                        <TableCell sx={{ paddingLeft: "50px" }}>
                          {index === 9 && active !== 1
                            ? active.toString() + 0
                            : active !== 1
                            ? (active - 1).toString() + (index + 1)
                            : index + 1}
                        </TableCell>
                        <TableCell>
                          <div className={styles.grid}>
                            <img
                              src={PICTURE + item?.sender_avatar}
                              className={styles.profilePic}
                              alt="avatar"
                              onError={(e) =>
                                (e.target.src = ImagePlaceholder2)
                              }
                            />
                            <span className={styles.name}>
                              {item?.sender_name
                                ? item?.sender_name
                                : "Not Provided"}
                            </span>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className={styles.grid}>
                            <img
                              src={PICTURE + item?.receiver_avatar}
                              className={styles.profilePic}
                              alt="avatar"
                              onError={(e) =>
                                (e.target.src = ImagePlaceholder2)
                              }
                            />
                            <span className={styles.name}>
                              {item?.receiver_name
                                ? item?.receiver_name
                                : "Not Provided"}
                            </span>
                          </div>
                        </TableCell>
                        <TableCell>{item?.location_name}</TableCell>
                        <TableCell>
                          <div
                            className={styles.grid}
                            onClick={() => handleOpenDetails(item)}
                          >
                            <RemoveRedEyeIcon style={{ cursor: "pointer" }} />
                          </div>
                        </TableCell>{" "}
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
          <Feedback
            open={openDetails}
            handleClose={handleCloseDetail}
            selectedItem={selectedItem}
          />
        </TableContainer>
      ) : (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <NoData />
        </div>
      )}

      {Math.ceil(count / 10) > 1 ? (
        <div className={styles.paginationContainer}>
          <Pagination
            count={Math.ceil(count / 10)}
            page={active}
            onChange={handleChangePage}
            variant="outlined"
            shape="rounded"
            sx={{
              "& .MuiPaginationItem-root": {
                height: "50px",
                width: "50px",
                fontSize: "1rem",
              },
              "& .Mui-selected": {
                backgroundColor: "#435ebe !important",
                color: "white",
                border: "none",
              },
            }}
          />
        </div>
      ) : null}
    </div>
  );
}

export default DatesFeedback;
