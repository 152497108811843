import React, { useState, useEffect } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { toastMessage } from 'shared/toast'
import { Typography } from '@mui/material'
import CustomButton from "shared/components/customButton";
import styles from "./style.module.scss"
import EditorLoader from "shared/skeletonLoader/editorLoader"
import { useLogoutHelper } from 'utils/logoutHelper'


export default function MyEditor({ text, setText, getAPI, updateAPI, type }) {
  const { handleLogout } = useLogoutHelper()
  const [loader, setLoader] = useState(false)
  const [editorLoader, setEditorLoader] = useState(true)
  function handleTextChange(value) {
    setText(value)
  }

  const modules = {
    toolbar: [
      [{ header: '1' }, { header: '2' }],
      [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      ['clean'],
    ],
  }

  const formats = [
    'header',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'image',
    'video',
  ]

  const getText = () => {
    setEditorLoader(true)
    getAPI()
      .then(({ data } = data) => {
        setEditorLoader(false)

        if (data?.data?.terms_and_conditions) {
          setText(data.data?.terms_and_conditions?.description)
        } else {
          setText(data?.data?.privacy_policy?.description)
        }
      })
      .catch((err) => {
        setEditorLoader(false)
        toastMessage(err?.response?.data?.message, "error");
        if (err?.response?.status == 401) {
          handleLogout()
        }
      })
  }

  useEffect(() => {
    getText()
  }, [])

  const handleUpdate = () => {
    let obj = {
      description: text,
    }

    if (type == 'terms') {
      obj['title'] = 'Terms & Conditions'
    } else {
      obj['title'] = 'Privacy Policy'
    }


    setLoader(true)
    updateAPI(obj)
      .then(({ data } = data) => {
        setLoader(false)
        toastMessage(data?.message)
      })
      .catch((err) => {
        setLoader(false)
        toastMessage(err?.response?.message, 'error')
      })
  }

  return (
    <div>
      <Typography
        sx={{
          fontStyle: 'normal',
          fontWeight: 600,
          fontSize: 32,
          lineHeight: '48px',
          letterSpacing: '-0.5px',
          color: '#11142D',
          marginBottom: '2%',
        }}
      >
      </Typography>
      <Typography className={styles.editorTitle}>
        {type == 'terms' ? 'Terms and Condition' : 'Privacy Policy'}
      </Typography>
      {editorLoader ? (
        <>
          <EditorLoader />
        </>
      ) : (
        <ReactQuill
          value={text}
          onChange={handleTextChange}
          modules={modules}
          formats={formats}
        />
      )}
      {!editorLoader ? <CustomButton handleClick={handleUpdate} text={"Update"} loader={loader} /> : ""}
    </div>
  )
}
