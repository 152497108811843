import React, { useEffect, useState } from "react";
import { blockUserService, getAllUsersService, unBlockUserService } from "services/user";
import CustomTable from "shared/components/customTable";
import Pagination from "shared/components/pagination";
import SkeletonLoader from "shared/skeletonLoader";
import { toastMessage } from "shared/toast";


let header = ["Account", "Platform", "Details", "Action"];

function Users() {
  const [statusLoader, setStatusLoader] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const [loader, setLoader] = useState(false);
  const [lastPage, setLastPage] = useState(0);
  const [active, setActive] = useState(1);

  const getAllUsers = () => {
    setLoader(true)
    getAllUsersService(active)
      .then(({ data: { data: { users } } }) => {
        setLastPage(users?.last_page)
        setAllUsers(users?.data)
        setLoader(false)
      })
      .catch((err) => {
        setLoader(false)
      })
  }

  useEffect(() => {
    getAllUsers()
  }, [active])

  const handleStatus = (check, item) => {
    setStatusLoader(true);
    if (check == "Block") {
      blockUserService(item?.id)
        .then(({ data }) => {
          setStatusLoader(false);
          setUsers();
        })
        .catch((err) => {

        })
    } else if (check == "Unblock") {
      unBlockUserService(item?.id)
        .then(({ data }) => {
          setStatusLoader(false);
          setUsers();
        })
        .catch((err) => {

        })
    }

    function setUsers() {
      let temp = [...allUsers];
      let index = temp.findIndex((i) => i?.id == item?.id);
      if (index > -1) {
        temp[index].is_blocked = !temp[index].is_blocked
        toastMessage(item?.name ? `${item?.name} ${check == "Block" ? "blocked" : "unblocked"}` : `${check == "Block" ? "Blocked" : "Unblocked"}`)
        setAllUsers(temp);
      }
    }
  }

  return (
    <>
      <CustomTable
        header={header}
        tableData={allUsers}
        tableHeading={"All Users"}
        table={"user"}
        handleStatus={handleStatus}
        statusLoader={statusLoader}
        active={active}
        loader={loader}
      />
      <Pagination lastPage={lastPage} active={active} setActive={setActive} loader={loader} />
    </>
  )
}

export default Users;
