import './App.css';
import { ToastContainer } from 'react-toastify'
import AllRoutes from 'routes/routes';


function App() {
  return (
    <div>
      <AllRoutes />
      <ToastContainer />
    </div>
  );
}

export default App;
