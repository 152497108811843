import React, { useEffect, useState } from "react";
import CustomTable from "shared/components/customTable";
import { toastMessage } from "shared/toast";
import { blockUserService, getAllBlockedUsersService, unBlockUserService } from "services/user";
// import Pagination from "shared/components/pagination";
import { useLogoutHelper } from 'utils/logoutHelper';
import { Pagination } from "@mui/material";
import styles from "./style.module.scss"


let header = ["Sr.", "Name", "Platform", "Details", "Action"];


function BlockedUser() {
  const [statusLoader, setStatusLoader] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const [loader, setLoader] = useState(false);
  const [lastPage, setLastPage] = useState(0);
  const [active, setActive] = useState(1);
  const { handleLogout } = useLogoutHelper();
  const [count, setCount] = useState(null)



  const getAllUsers = () => {
    setLoader(true)
    getAllBlockedUsersService(active)
      .then(({ data: { data: { blockedUsers } } }) => {
        setLastPage(blockedUsers?.last_page)
        setAllUsers(blockedUsers?.data)
        setLoader(false);
        setCount(blockedUsers?.total)
      })
      .catch((err) => {
        setLoader(false);
        toastMessage(err?.response?.data?.message, "error");
        if (err?.response?.status === 401) {
          handleLogout()
        }
      })
  }

  useEffect(() => {
    getAllUsers()
  }, [active])


  const handleStatus = (check, item) => {
    setStatusLoader(true);
    if (check === "Block") {
      blockUserService(item?.id)
        .then(({ data }) => {
          setStatusLoader(false);
          setUsers();
        })
        .catch((err) => {
          toastMessage(err?.response?.data?.message, "error");
          setStatusLoader(false);
          if (err?.response?.status === 401) {
            handleLogout()
          }
        })
    } else if (check === "Unblock") {
      unBlockUserService(item?.id)
        .then(({ data }) => {
          setStatusLoader(false);
          setUsers();
        })
        .catch((err) => {
          toastMessage(err?.response?.data?.message, "error");
          setStatusLoader(false);
          if (err?.response?.status === 401) {
            handleLogout()
          }
        })
    }

    function setUsers() {
      let temp = [...allUsers];
      let index = temp.findIndex((i) => i?.id === item?.id);
      temp.splice(index, 1)
      setAllUsers(temp);
      toastMessage(`${item?.name} ${check === "Block" ? "blocked" : "unblocked"}`)

      // if (index > -1) {
      //   temp[index].is_blocked = !temp[index].is_blocked
      //   toastMessage(`${item?.name} ${check == "Block" ? "blocked" : "unblocked"}`)
      //   setAllUsers(temp);
      // }
    }
  }

  const handleChangePage = (event, newPage) => {
    setActive(newPage);
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };


  return (
    <>
      <CustomTable
        header={header}
        tableData={allUsers}
        tableHeading={"All Blocked Users"}
        table={"user"}
        handleStatus={handleStatus}
        statusLoader={statusLoader}
        active={active}
        loader={loader}
      />
      {/* {allUsers.length ? <Pagination lastPage={lastPage} active={active} setActive={setActive} loader={loader} /> : ""} */}
      {Math.ceil(count / 10) > 1 ?
        <div className={styles.paginationContainer}>
          <Pagination
            count={Math.ceil(count / 10)}
            page={active}
            onChange={handleChangePage}
            variant="outlined"
            shape="rounded"
            sx={{
              '& .MuiPaginationItem-root': {
                height: '50px',
                width: "50px",
                fontSize: "1rem"
              },
              '& .Mui-selected': {
                backgroundColor: '#435ebe !important',
                color: 'white',
                border: "none"
              },
            }}
          />
        </div>
        : null}
    </>
  )
}

export default BlockedUser;
