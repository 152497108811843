import { Pagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getAllReportedUserService } from "services/user";
import CustomTable from "shared/components/customTable";
// import Pagination from "shared/components/pagination";
import SkeletonLoader from "shared/skeletonLoader";
import { toastMessage } from "shared/toast";
import { useLogoutHelper } from 'utils/logoutHelper'
import styles from "./style.module.scss"




let header = ["Sr.", "Reported to", "Reported by", "Reason"];


function ReportedUser() {
  const [statusLoader, setStatusLoader] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const [loader, setLoader] = useState(true);
  const [lastPage, setLastPage] = useState(0);
  const [active, setActive] = useState(1);
  const { handleLogout } = useLogoutHelper();
  const [count, setCount] = useState(null)




  const getAllReportedUsers = () => {
    setLoader(true)
    getAllReportedUserService(active)
      .then(({ data: { data: { reportedUsers } } }) => {
        setAllUsers(reportedUsers?.data)
        setLastPage(reportedUsers?.last_page)
        setLoader(false)
        setCount(reportedUsers?.total)
      })
      .catch((err) => {
        setLoader(false);
        toastMessage(err?.response?.data?.message, "error");
        if (err?.response?.status == 401) {
          handleLogout()
        }
      })
  }

  useEffect(() => {
    getAllReportedUsers();
  }, [active])

  const handleChangePage = (event, newPage) => {
    setActive(newPage);
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };



  return (
    <>
      <CustomTable
        header={header}
        tableData={allUsers}
        tableHeading={"Reported Users"}
        table={"reported"}
        statusLoader={statusLoader}
        loader={loader}
        active={active}
      />
      {/* {allUsers.length ? <Pagination lastPage={lastPage} active={active} setActive={setActive} loader={loader} /> : ""} */}
      {Math.ceil(count / 10) > 1 ?
        <div className={styles.paginationContainer}>
          <Pagination
            count={Math.ceil(count / 10)}
            page={active}
            onChange={handleChangePage}
            variant="outlined"
            shape="rounded"
            sx={{
              '& .MuiPaginationItem-root': {
                height: '50px',
                width: "50px",
                fontSize: "1rem"
              },
              '& .Mui-selected': {
                backgroundColor: '#435ebe !important',
                color: 'white',
                border: "none"
              },
            }}
          />
        </div>
        : null}
    </>

  )
}

export default ReportedUser;
