import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import styles from "./style.module.scss";
import ReactCompareImage from "react-compare-image";

import CustomButton from "shared/components/customButton";

import CloseIcon from "@mui/icons-material/Close";
import Selfie1 from "assets/images/selfie1.jpeg";
import Selfie2 from "assets/images/selfie2.jpeg";
import Selfie3 from "assets/images/selfie3.jpeg";
import Selfie4 from "assets/images/selfie4.jpeg";
import Selfie5 from "assets/images/selfie5.jpeg";
import Selfie6 from "assets/images/selfie6.jpeg";
import SelfieCompare from "assets/images/SelfieProfilePic.jpeg";
import { PICTURE } from "utils/constant";
import { ImagePlaceholder } from "assets";
import SkeletonLoader from "shared/skeletonLoader";

let ImagesArr = [
  { id: 1, src: Selfie1 },
  { id: 2, src: Selfie2 },
  { id: 3, src: Selfie3 },
  { id: 4, src: Selfie4 },
  { id: 5, src: Selfie5 },
  { id: 6, src: Selfie6 },
];

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({
  open,
  handleClickOpen,
  handleClose,
  action,
  selectedItem,
  handleStatus,
}) {
  const [galleryOpen, setGalleryOpen] = useState(false);
  const [images, setImages] = useState(ImagesArr);
  const [loader, setLoader] = useState(false);
  const [hover, setHover] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [filterImageBool, setFilterImageBool] = useState(false);

  const handleGallery = (item) => {
    let temp = [...images];
    let index = temp.findIndex((i) => i?.id == item?.id);
    if (index > -1) {
      let removedItem = images.splice(index, 1);
      temp.unshift(removedItem[0]);
    }
    setImages([...new Set(temp)]);
    setGalleryOpen(true);
  };

  const handleAction = () => {
    handleStatus("Verify", selectedItem);
  };

  const handleCompare = () => {};

  const setMouseHover = (item) => {
    setHover(true);
    setSelectedImage(item);
  };

  const setMouseLeave = (item) => {
    setHover(false);
    setSelectedImage(null);
  };

  useEffect(() => {
    setLoader(true);
    setTimeout(() => {
      setLoader(false);
    }, 2000);
  }, [open]);

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        className="modalContainer"
      >
        <DialogContent>
          <CloseIcon
            sx={{
              position: "absolute",
              top: "24px",
              right: "24px",
              cursor: "pointer",
            }}
            onClick={handleClose}
          />
          <div className={styles.label}>
            {selectedItem?.name}'s Face verification
          </div>
          <div className={styles.subHeading}>Move slider to compare angle.</div>

          {!loader ? (
            <div className={styles.imageContainer}>
              {!(selectedItem?.face_verification_pictures?.length > 0) ? (
                <div className={styles.noImage}>No Images Available</div>
              ) : (
                selectedItem?.face_verification_pictures?.map((item, inx) => {
                  return (
                    <>
                      <ReactCompareImage
                        leftImage={PICTURE + item?.image}
                        rightImage={PICTURE + item?.pose_image}
                        key={inx}
                        rightImageCss={{ objectFit: "cover" }}
                        leftImageCss={{ objectFit: "cover" }}
                        loader={
                          <h1 className={styles.checking}>Loading...!</h1>
                        }
                        skeleton={
                          <h1 className={styles.checking}>Loading...!</h1>
                        }
                      />
                    </>
                  );
                })
              )}
            </div>
          ) : (
            <SkeletonLoader style={styles.skeletonLoader} />
          )}
          {/* <CustomButton handleClick={() => handleAction()} text={"Verify"} loader={loader} /> */}
        </DialogContent>
      </Dialog>
    </div>
  );
}
