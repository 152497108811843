import React, { useEffect, useRef, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import styles from "./style.module.scss";
import CloseIcon from "@mui/icons-material/Close";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import { PICTURE } from "utils/constant";
import { ImagePlaceholder } from "assets";
import moment from "moment";
import CollectionsIcon from "@mui/icons-material/Collections";
import { connect } from "react-redux";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({
  open,
  handleClickOpen,
  handleClose,
  selectedItem,
}) {
  const dialogContentRef = useRef(null);
  const [images, setImages] = useState([]);
  const [galleryOpen, setGalleryOpen] = useState(false);

  console.log("selectedItem", selectedItem);

  useEffect(() => {
    if (open) {
      dialogContentRef.current.scrollTo(0, 0);
    }
  }, [open]);

  useEffect(() => {
    let images = [];
    if (selectedItem?.user_images?.length > 0) {
      selectedItem?.user_images?.map((i) => {
        let obj = {
          id: i?.id,
          src: PICTURE + i?.url,
        };

        images.push(obj);
      });
    }
    setImages(images);
  }, [selectedItem]);

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        className={styles.modalContainer}
      >
        <DialogContent ref={dialogContentRef} className={styles.content}>
          <CloseIcon
            sx={{
              position: "absolute",
              top: "24px",
              right: "24px",
              cursor: "pointer",
            }}
            onClick={handleClose}
          />

          <div className={styles.label}>
            {selectedItem?.name
              ? selectedItem?.name + `'s detail`
              : "View Detail"}
          </div>
          <div className={styles.imgContainer}>
            {selectedItem?.avatar ? (
              <img
                src={PICTURE + selectedItem?.avatar}
                className={styles.profilePic}
              />
            ) : (
              <ImagePlaceholder className={styles.profilePicPlaceHolder} />
            )}
          </div>

          <div style={{ marginTop: "50px" }}>
            <div style={{ marginBottom: "20px" }}>
              <div className={styles.sectionContainer}>
                <span className={styles.sectionTitle}>Gallery</span>
                {selectedItem?.user_images?.length === 0 ? (
                  <span className={styles.sectionTitle}>No image added</span>
                ) : (
                  <span
                    className={styles.sectionTitle}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => setGalleryOpen(true)}
                  >
                    View{" "}
                    <CollectionsIcon
                      style={{ cursor: "pointer", marginLeft: "10px" }}
                    />
                  </span>
                )}
              </div>
            </div>

            <div className={styles.sectionHeading}>Bio</div>
            <div
              className={styles.sectionContainer}
              style={{ marginBottom: "20px" }}
            >
              <span className={styles.sectionTitle}>
                {selectedItem?.user_meta?.bio
                  ? selectedItem?.user_meta?.bio
                  : "Not Provided"}
              </span>
            </div>

            <div className={styles.sectionHeading}>Basic info</div>
            <Section
              heading={"Email"}
              detail={
                selectedItem?.email ? selectedItem?.email : "Not Provided"
              }
            />
            <Section
              heading={"Profile Name"}
              detail={selectedItem?.name ? selectedItem?.name : "Not Provided"}
            />
            <Section
              heading={"Joined"}
              detail={moment(selectedItem?.created_at).format("MMM D, YYYY")}
            />
            <Section
              heading={"Date of Birth"}
              detail={moment(selectedItem?.date_of_birth).format("MMM D, YYYY")}
            />
            <Section
              heading={"Gender"}
              detail={
                selectedItem?.user_gender?.gender
                  ? selectedItem?.user_gender?.gender
                  : "Not Provided"
              }
            />
            <Section
              heading={"Sexuality"}
              detail={
                selectedItem?.sexual_orientation?.title
                  ? selectedItem?.sexual_orientation?.title
                  : "Not Provided"
              }
            />
            <Section
              heading={"Interested in"}
              detail={
                selectedItem?.interested_in_gender?.gender
                  ? selectedItem?.interested_in_gender?.gender
                  : "Not Provided"
              }
            />
            <Section
              heading={"Phone"}
              detail={
                selectedItem?.phone_number
                  ? selectedItem?.phone_number
                  : "Not Provided"
              }
            />
            <Section
              heading={"Status"}
              detail={selectedItem?.is_blocked ? "Blocked" : "Active"}
            />

            <div className={styles.sectionHeading}>Interests</div>
            <div className={styles.interestContainer}>
              {selectedItem?.user_interest?.length > 0 ? (
                selectedItem?.user_interest?.map((item) => {
                  return (
                    <div
                      id={styles.interestTags}
                      className={styles.sectionTitle}
                    >
                      {item?.interest?.text}
                    </div>
                  );
                })
              ) : (
                <div>
                  <div className={styles.sectionContainer}>
                    <span className={styles.sectionTitle}>Not Provided</span>
                  </div>
                </div>
              )}
            </div>

            <div className={styles.sectionHeading}>Education</div>
            <Section
              heading={"Education"}
              detail={
                selectedItem?.user_meta?.education?.name
                  ? selectedItem?.user_meta?.education?.name
                  : "Not Provided"
              }
            />

            <div className={styles.sectionHeading}>Language and Ethnicity</div>
            <Section
              heading={"Ethnic Group"}
              detail={
                selectedItem?.user_meta?.ethnic_group?.name
                  ? selectedItem?.user_meta?.ethnic_group?.name
                  : "Not Provided"
              }
            />
            <Section
              heading={"Language"}
              detail={
                selectedItem?.user_meta?.language?.name
                  ? selectedItem?.user_meta?.language?.name
                  : "Not Provided"
              }
            />

            <div className={styles.sectionHeading}>Lifestyle</div>
            <Section
              heading={"Religiosity"}
              detail={
                selectedItem?.user_meta?.religion?.name
                  ? selectedItem?.user_meta?.religion?.name
                  : "Not Provided"
              }
            />
            <Section
              heading={"Alcohol"}
              detail={
                selectedItem?.user_meta?.alcohol?.name
                  ? selectedItem?.user_meta?.alcohol?.name
                  : "Not Provided"
              }
            />
            <Section
              heading={"Smoker"}
              detail={
                selectedItem?.user_meta?.smoke?.name
                  ? selectedItem?.user_meta?.smoke?.name
                  : "Not Provided"
              }
            />

            <div className={styles.sectionHeading}>About me section</div>

            <Section
              heading={"Height"}
              detail={
                selectedItem?.user_meta?.height
                  ? selectedItem?.user_meta?.height + " inches"
                  : "Not Provided"
              }
            />
            <Section
              heading={"Marital Status"}
              detail={
                selectedItem?.user_meta?.martial_status?.name
                  ? selectedItem?.user_meta?.martial_status?.name
                  : "Not Provided"
              }
            />
            <Section
              heading={"Children"}
              detail={
                selectedItem?.user_meta?.children?.name
                  ? selectedItem?.user_meta?.children?.name
                  : "Not Provided"
              }
            />
          </div>
        </DialogContent>
        <Lightbox
          open={galleryOpen}
          carousel={1}
          close={() => setGalleryOpen(false)}
          slides={images}
          plugins={[Thumbnails, Zoom, Fullscreen, Slideshow]}
        />
      </Dialog>
    </div>
  );
}

const Section = ({ heading, detail }) => {
  return (
    <div style={{ marginBottom: "20px" }}>
      <div className={styles.sectionContainer}>
        <span className={styles.sectionTitle}>{heading}</span>
        <span className={styles.sectionTitle}>{detail}</span>
      </div>
    </div>
  );
};
