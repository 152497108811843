import React from "react";
import { DefaulLoader } from "assets";
import Animation from "./Animation";

function Loader() {
  return (
    <div className="loader">
      <div style={{ height: "auto" }}>
        <Animation Pic={DefaulLoader} />
      </div>
    </div>
  );
}

export default Loader;
