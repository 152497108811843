import { HTTP_Request } from "utils/interceptor";
import { BASE_URL } from "utils/constant";
import { Endpoints } from "./endpoint";


const addPoseService = (obj) => {
  let formBody = new FormData();
  Object.keys(obj).forEach((key) => {
    formBody.append(key, obj[key]);
  });
  return HTTP_Request.post(BASE_URL + Endpoints.addPose, formBody);
}

const getAllPosesService = () => {
  return HTTP_Request.get(BASE_URL + Endpoints.getAllPoses)
}

const deletePoseService = (id) => {
  return HTTP_Request.delete(BASE_URL + Endpoints.deletePose + id)
}

const getAllListofVerification = (active) => {
  return HTTP_Request.get(BASE_URL + Endpoints.getFaceVerificationUsers + `?page=${active}`)
}

const verifyFaceUser = (obj) => {
  return HTTP_Request.post(BASE_URL + Endpoints.verifyFace, obj)

}

const rejectFaceVerificationRequest = (obj) => {
  return HTTP_Request.post(BASE_URL + Endpoints.rejectFaceVerificationRequest, obj)

}

export { addPoseService, getAllPosesService, deletePoseService, getAllListofVerification, verifyFaceUser, rejectFaceVerificationRequest };
