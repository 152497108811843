import { Pagination } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { changeGenderStatus, createGenderService, deleteGenderService, getGenderService, updateGenderService } from 'services/profileServices';
import CustomPreferenceTable from 'shared/components/customPreferenceTable';
// import Pagination from 'shared/components/pagination';
import { toastMessage } from 'shared/toast';
import { useLogoutHelper } from 'utils/logoutHelper'
import styles from "./style.module.scss"


function Genders() {
  const [allGenders, setAllGenders] = useState([]);
  const [editLoader, setEditLoader] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [addLoader, setAddLoader] = useState(false);
  const [loader, setLoader] = useState(false)
  let header = ["Sr", "Title", "Actions"];
  const { handleLogout } = useLogoutHelper();
  const [lastPage, setLastPage] = useState(0);
  const [count, setCount] = useState(null)
  const [active, setActive] = useState(1);



  const handleEdit = (changeText, selectedItem) => {
    setEditLoader(true)
    updateGenderService({ gender: changeText }, selectedItem?.id)
      .then(({ data: { data: { isUpdated }, message } }) => {
        toastMessage(message)
        if (isUpdated) {
          let temp = [...allGenders];
          let index = temp.findIndex((item) => item?.id == selectedItem?.id);
          temp[index].gender = changeText;
          setAllGenders(temp);
          setEditLoader(false)
        }
      })
      .catch((err) => {
        setEditLoader(false)
        toastMessage(err?.response?.data?.message, "error");
        if (err?.response?.status == 401) {
          handleLogout()
        }
      })
  }

  const handleDelete = (item) => {
    setDeleteLoader(true)
    deleteGenderService(item?.id)
      .then(({ data: { data: { isDeleted }, message } }) => {
        if (isDeleted == 1) {
          toastMessage(message)
          const updatedInterests = allGenders.filter((gender) => gender.id !== item.id);
          setAllGenders(updatedInterests);
          setDeleteLoader(false)
        }
      })
      .catch((err) => {
        setDeleteLoader(false)
        toastMessage(err?.response?.data?.message, "error");
        if (err?.response?.status == 401) {
          handleLogout()
        }
      })
  }

  const handleAdd = (text) => {
    setAddLoader(true);
    createGenderService({ gender: text })
      .then(({ data: { data: { gender }, message } }) => {
        toastMessage(message)
        setAddLoader(false);
        setAllGenders([gender, ...allGenders])
      })
      .catch((err) => {
        setAddLoader(false);
        toastMessage(err?.response?.data?.message, "error");
        if (err?.response?.status == 401) {
          handleLogout()
        }
      })
  }

  const getAllGenders = () => {
    setLoader(true)
    getGenderService(active)
      .then(({ data: { data: { genders } } }) => {
        setAllGenders(genders?.data);
        setLoader(false)
        setLastPage(genders.last_page)
        setCount(genders?.total)
      })
      .catch((err) => {
        setLoader(false)
        toastMessage(err?.response?.data?.message, "error");
        if (err?.response?.status == 401) {
          handleLogout()
        }
      })
  }

  useEffect(() => {
    getAllGenders();
  }, [active])

  const handleStatus = (item) => {
    let temp = [...allGenders];
    let index = temp.findIndex((i) => i?.id == item?.id);
    if (index > -1) {
      changeGenderStatus({ id: item?.id, status: temp[index]?.is_active === 1 ? 0 : 1 })
        .then(({ data: { data, message } }) => {
          temp[index]['is_active'] = (temp[index]?.is_active === 1 ? 0 : 1);
          toastMessage(message)
        })
        .catch((err) => {
          toastMessage(err?.response?.data?.message, "error");
          if (err?.response?.status == 401) {
            handleLogout()
          }
        })
    }
    setAllGenders(temp);
  };

  const handleChangePage = (event, newPage) => {
    setActive(newPage);
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  return (
    <div>
      <CustomPreferenceTable
        header={header}
        tableData={allGenders}
        editChange={handleEdit}
        onDelete={handleDelete}
        heading={"Gender"}
        editLoader={editLoader}
        deleteLoader={deleteLoader}
        handleAddition={handleAdd}
        addLoader={addLoader}
        handleStatus={handleStatus}
        loader={loader}
        active={active}
      />
      {/* {allGenders?.length ? <Pagination lastPage={lastPage} active={active} setActive={setActive} loader={loader} /> : " "} */}
      {Math.ceil(count / 10) > 1 ?
        <div className={styles.paginationContainer}>
          <Pagination
            count={Math.ceil(count / 10)}
            page={active}
            onChange={handleChangePage}
            variant="outlined"
            shape="rounded"
            sx={{
              '& .MuiPaginationItem-root': {
                height: '50px',
                width: "50px",
                fontSize: "1rem"
              },
              '& .Mui-selected': {
                backgroundColor: '#435ebe !important',
                color: 'white',
                border: "none"
              },
            }}
          />
        </div>
        : null}

    </div>
  )
}

export default Genders



