import { Pagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getAllListofVerification, verifyFaceUser, rejectFaceVerificationRequest } from "services/verification";
import CustomTable from "shared/components/customTable";
// import Pagination from "shared/components/pagination";
import { toastMessage } from "shared/toast";
import { useLogoutHelper } from 'utils/logoutHelper'
import styles from "./style.module.scss"


let header = ["Sr.", "Users", "Face Verification", "Action"];


function Users() {
  const [statusLoader, setStatusLoader] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const [loader, setLoader] = useState(true);
  const [lastPage, setLastPage] = useState(0);
  const [active, setActive] = useState(1);
  const { handleLogout } = useLogoutHelper();
  const [count, setCount] = useState(null)



  const getAllUsers = () => {
    setLoader(true)
    getAllListofVerification(active)
      .then(({ data: { data: { users } } }) => {
        setAllUsers(users?.data);
        setLoader(false);
        setLastPage(users?.last_page);
        setCount(users?.total)
      })
      .catch((err) => {
        setLoader(false)
      })
  }

  useEffect(() => {
    getAllUsers();
  }, [active])



  const handleStatus = (check, item) => {
    setStatusLoader(true)
    verifyFaceUser({ user_id: item?.id })
      .then(({ data: { message } }) => {
        toastMessage(message);
        setStatusLoader(false);
        let temp = [...allUsers];
        let index = temp.findIndex((i) => i?.id == item?.id);
        if (index > -1) {
          temp.splice(index, 1)
        }
        setAllUsers(temp)
      })
      .catch((err) => {
        setStatusLoader(false);
        toastMessage(err?.message);
        if (err?.response?.status == 401) {
          handleLogout()
        }
      })
  }

  const handleRejectionStatus = (check, item) => {
    setStatusLoader(true)
    rejectFaceVerificationRequest({ user_id: item?.id })
      .then(({ data: { message } }) => {
        toastMessage(message);
        setStatusLoader(false);
        let temp = [...allUsers];
        let index = temp.findIndex((i) => i?.id == item?.id);
        if (index > -1) {
          temp.splice(index, 1)
        }
        setAllUsers(temp)
      })
      .catch((err) => {
        setStatusLoader(false);
        toastMessage(err?.message);
        if (err?.response?.status == 401) {
          handleLogout()
        }
      })    
  }


  const handleChangePage = (event, newPage) => {
    setActive(newPage);
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };


  return <div>
    <CustomTable
      header={header}
      tableData={allUsers}
      tableHeading={"Face verification"}
      table={"face"}
      handleStatus={handleStatus}
      handleRejection={handleRejectionStatus}
      statusLoader={statusLoader}
      loader={loader}
      active={active}
    />
    {/* {allUsers?.length ? <Pagination lastPage={lastPage} active={active} setActive={setActive} loader={loader} /> : " "} */}
    {Math.ceil(count / 10) > 1 ?
      <div className={styles.paginationContainer}>
        <Pagination
          count={Math.ceil(count / 10)}
          page={active}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
          sx={{
            '& .MuiPaginationItem-root': {
              height: '50px',
              width: "50px",
              fontSize: "1rem"
            },
            '& .Mui-selected': {
              backgroundColor: '#435ebe !important',
              color: 'white',
              border: "none"
            },
          }}
        />
      </div>
      : null}
  </div>;
}

export default Users;
