import React, { useEffect, useState } from "react";
import { activateUserService, blockUserService, getAllUsersService, getDeactivated, unBlockUserService } from "services/user";
import CustomTable from "shared/components/customTable";
import Pagination from "shared/components/pagination";
import SkeletonLoader from "shared/skeletonLoader";
import { toastMessage } from "shared/toast";
import { useLogoutHelper } from 'utils/logoutHelper'
import styles from "./style.module.scss";


let header = ["Sr.", "Users", "Reason", "Action"];

function Users() {
  const [statusLoader, setStatusLoader] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const [loader, setLoader] = useState(false);
  const [lastPage, setLastPage] = useState(0);
  const [active, setActive] = useState(1);
  const [search, setSearch] = useState("");
  const { handleLogout } = useLogoutHelper();
  const [count, setCount] = useState(null)



  const getAllDeactivatedUser = () => {
    setLoader(true)
    getDeactivated(active)
      .then(({ data: { data } }) => {
        setLastPage(data['deactivated-users']?.last_page)
        setAllUsers(data['deactivated-users']?.data)
        setLoader(false)
        setCount(data['deactivated-users'].total)
      })
      .catch((err) => {
        setLoader(false)
        toastMessage(err?.response?.data?.message, "error");
        if (err?.response?.status == 401) {
          handleLogout()
        }
      })
  }

  useEffect(() => {
    getAllDeactivatedUser()
  }, [active]);

  const handleStatus = (check, item) => {
    setStatusLoader(true);
    activateUserService(item?.id)
      .then(({ data: { data } }) => {
        setStatusLoader(false);
        if (data['is-activated'])
          toastMessage("user activated successfully");
        let temp = [...allUsers];
        let index = temp.findIndex((i) => i?.id == item?.id);
        if (index > -1) {
          temp.splice(index, 1);
          setAllUsers(temp)
        }
      })
      .catch((err) => {
        setStatusLoader(false);
        toastMessage(err?.response?.data?.message, "error");
        if (err?.response?.status == 401) {
          handleLogout()
        }
      })
  }

  const handleChangePage = (event, newPage) => {
    setActive(newPage);
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  return (
    <>
      <CustomTable
        header={header}
        tableData={allUsers}
        tableHeading={"Deactivated Users"}
        table={"deactivated"}
        active={active}
        handleStatus={handleStatus}
        statusLoader={statusLoader}
        loader={loader}
        setSearch={setSearch}
        search={search}
      />
      {/* {allUsers.length ? <Pagination lastPage={lastPage} active={active} setActive={setActive} loader={loader} /> : ""} */}
      {Math.ceil(count / 10) > 1 ?
        <div className={styles.paginationContainer}>
          <Pagination
            count={Math.ceil(count / 10)}
            page={active}
            onChange={handleChangePage}
            variant="outlined"
            shape="rounded"
            sx={{
              '& .MuiPaginationItem-root': {
                height: '50px',
                width: "50px",
                fontSize: "1rem"
              },
              '& .Mui-selected': {
                backgroundColor: '#435ebe !important',
                color: 'white',
                border: "none"
              },
            }}
          />
        </div>
        : null}
    </>
  )
}

export default Users;
