import React, { useState, useEffect } from 'react';
import { changeSexualOrientationStatus, createSexualOrientationService, deleteSexualOrientationService, getSexualOrientationService, updateSexualOrientationService } from 'services/profileServices';
import CustomPreferenceTable from 'shared/components/customPreferenceTable';
import { useLogoutHelper } from 'utils/logoutHelper';
import { toastMessage } from 'shared/toast';
import { Pagination } from '@mui/material';
// import Pagination from 'shared/components/pagination';
import styles from "./style.module.scss"



function SexualOrientation() {
  const [allSexualOrientation, setAllSexualOrientation] = useState([]);
  const [editLoader, setEditLoader] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [addLoader, setAddLoader] = useState(false);
  const [loader, setLoader] = useState(false)
  const { handleLogout } = useLogoutHelper()
  const [lastPage, setLastPage] = useState(0);
  const [active, setActive] = useState(1);
  const [count, setCount] = useState(null)
  let header = ["Sr", "Title", "Actions", "Order"];

  const handleEdit = (changeText, selectedItem) => {
    setEditLoader(true)
    updateSexualOrientationService({ title: changeText }, selectedItem?.id)
      .then(({ data: { data: { isUpdated }, message } }) => {
        toastMessage(message)
        if (isUpdated) {
          let temp = [...allSexualOrientation];
          let index = temp.findIndex((item) => item?.id == selectedItem?.id);
          temp[index].title = changeText;
          setAllSexualOrientation(temp);
          setEditLoader(false);

        }
      })
      .catch((err) => {
        setEditLoader(false)
        toastMessage(err?.response?.data?.message, "error");
        if (err?.response?.status == 401) {
          handleLogout()
        }
      })
  }

  const handleDelete = (item) => {
    setDeleteLoader(true)
    deleteSexualOrientationService(item?.id)
      .then(({ data: { data, message, status } }) => {
        if (!status) {
          toastMessage(message, "error")
          setDeleteLoader(false);
        } else {
          if (data?.isDeleted == 1) {
            toastMessage(message)
            const updatedInterests = allSexualOrientation.filter((i) => i.id !== item.id);
            setAllSexualOrientation(updatedInterests);
            setDeleteLoader(false)
          }
        }

      })
      .catch((err) => {
        setDeleteLoader(false)
        toastMessage(err?.response?.data?.message, "error");
        if (err?.response?.status == 401) {
          handleLogout()
        }
      })
  }

  const handleAdd = (text) => {
    setAddLoader(true);
    createSexualOrientationService({ title: text })
      .then(({ data: { data: { orientation }, message } }) => {
        toastMessage(message)
        setAddLoader(false);
        setAllSexualOrientation([orientation, ...allSexualOrientation])
      })
      .catch((err) => {
        setAddLoader(false);
        toastMessage(err?.response?.data?.message, "error");
        if (err?.response?.status == 401) {
          handleLogout()
        }
      })
  }

  const getAllSexualOrientation = (check) => {
    if (!check) {
      setLoader(true)
    }

    getSexualOrientationService(active)
      .then(({ data: { data: { sexualOrientations } } }) => {
        setAllSexualOrientation(sexualOrientations?.data);
        setLoader(false);
        setLastPage(sexualOrientations?.last_page)
        setCount(sexualOrientations?.total)
      })
      .catch((err) => {
        setLoader(false)
        toastMessage(err?.response?.data?.message, "error");
        if (err?.response?.status == 401) {
          handleLogout()
        }
      })
  }

  useEffect(() => {
    getAllSexualOrientation();
  }, [active])

  const handleStatus = (item) => {
    let temp = [...allSexualOrientation];
    let index = temp.findIndex((i) => i?.id == item?.id);
    if (index > -1) {
      changeSexualOrientationStatus({ id: item?.id, status: temp[index]?.is_active === 1 ? 0 : 1 })
        .then(({ data: { data, message } }) => {
          temp[index]['is_active'] = (temp[index]?.is_active === 1 ? 0 : 1);
          toastMessage(message)
        })
        .catch((err) => {
          toastMessage(err?.response?.data?.message, "error");
          if (err?.response?.status == 401) {
            handleLogout()
          }
        })
    }
    setAllSexualOrientation(temp);
  };

  const handleChangePage = (event, newPage) => {
    setActive(newPage);
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  const handleOrder = () => {
    getAllSexualOrientation(true);
  }

  return (
    <div>
      <CustomPreferenceTable
        header={header}
        tableData={allSexualOrientation}
        editChange={handleEdit}
        onDelete={handleDelete}
        heading={"Sexual Orientation"}
        editLoader={editLoader}
        deleteLoader={deleteLoader}
        handleAddition={handleAdd}
        addLoader={addLoader}
        handleStatus={handleStatus}
        loader={loader}
        active={active}
        handleGetOrder={handleOrder}
      />
      {/* {allSexualOrientation?.length ? <Pagination lastPage={lastPage} active={active} setActive={setActive} loader={loader} /> : ""} */}
      {Math.ceil(count / 10) > 1 ?
        <div className={styles.paginationContainer}>
          <Pagination
            count={Math.ceil(count / 10)}
            page={active}
            onChange={handleChangePage}
            variant="outlined"
            shape="rounded"
            sx={{
              '& .MuiPaginationItem-root': {
                height: '50px',
                width: "50px",
                fontSize: "1rem"
              },
              '& .Mui-selected': {
                backgroundColor: '#435ebe !important',
                color: 'white',
                border: "none"
              },
            }}
          />
        </div>
        : null}
    </div>
  )
}

export default SexualOrientation;



