import axios from 'axios'
import { BASE_URL } from 'utils/constant'
import { store } from 'shared/redux/store'
import { toastMessage } from 'shared/toast'
import { logout } from 'shared/redux/userSlice'
export const HTTP_Request = axios.create({
  baseURL: BASE_URL,
})

HTTP_Request.interceptors.request.use(
  (config) => {
    const { user } = store.getState().root.user;
    if (user.token) {
      config.headers.Authorization = `Bearer ${user.token}`
    }
    return config
  },
  (err) => {
    if (err.response.status == 403) {
      toastMessage(err?.response?.data?.message, "error");
      store.dispatch(logout());
    }
    return Promise.reject(err);
  }
)

export const initialConfig = (user) => {
  // setupAxios(user);
}
