import { HTTP_Request } from "utils/interceptor";
import { BASE_URL } from "utils/constant";
import { Endpoints } from "./endpoint";

const getDateFeebacks = (obj) => {
  let formBody = new FormData();
  Object.keys(obj).forEach((key) => {
    formBody.append(key, obj[key]);
  });
  return HTTP_Request.post(BASE_URL + Endpoints.datesFeedBack, formBody);
};

export { getDateFeebacks };
