import React, { useEffect } from "react";
import Drawer from "shared/components/sidebar";

export default function Layout(props) {
  const changeTitle = () => {
    document.title = props.title + " | HUK'D";
  };
  useEffect(() => {
    changeTitle();
  });


  return (
    <>
      <div>
        <Drawer {...props} />
      </div>
    </>
  );
}
