import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { logout } from 'shared/redux/userSlice'
import { toastMessage } from 'shared/toast'

const useLogoutHelper = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleLogout = () => {
    dispatch(logout())
    navigate('/')
    toastMessage('Unathenticated request generated', 'error')
  }

  return { handleLogout }
}

export { useLogoutHelper }
