import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Avatar, TextField, Typography } from "@mui/material";
import styles from "./style.module.scss";
import ConfirmationModal from "shared/modals/confirmationModal";
import FaceVerificationModal from "shared/modals/faceVerificationModal";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import { PICTURE } from "utils/constant";
import {
  FemalePlaceholder,
  ImagePlaceholder,
  ImagePlaceholder2,
  MalePlaceholder,
  NoData,
} from "assets";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ViewDetails from "shared/modals/detail";

//icons
import CollectionsIcon from "@mui/icons-material/Collections";
import MoodIcon from "@mui/icons-material/Mood";
import SkeletonLoader from "shared/skeletonLoader";
import CloseIcon from "@mui/icons-material/Close";

export default function CustomTable({
  header,
  tableData,
  tableHeading,
  table,
  handleStatus,
  handleRejection,
  statusLoader,
  active,
  loader,
  search,
  setSearch,
  verifyLoader,
}) {
  const [open, setOpen] = useState(false);
  const [check, setCheck] = useState("");
  const [openRejectModal, setOpenRejectModal] = useState(false);
  const [rejectModalCheck, setRejectModalCheck] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [galleryModal, setGalleryModel] = useState(false);
  const [galleryOpen, setGalleryOpen] = useState(false);
  const [images, setImages] = useState([]);
  const [openDetails, setOpenDetails] = useState(false);

  // for blocking and unblocking user
  const handleClickOpen = (item, active) => {
    setOpen(true);
    setCheck(active ? active : item?.is_blocked ? "Unblock" : "Block");
    setSelectedItem(item);
  };

  const handleClickOpenRejectModal = (item, active) => {
    setOpenRejectModal(true);
    setRejectModalCheck(active ? active : item?.is_blocked ? "Unblock" : "Block");
    setSelectedItem(item);
  };

  const handleRejectionModalClose = () => setOpenRejectModal(false);

  const handleReject = (check) => {    
    handleRejection(check, selectedItem);
  };

  const handleClose = () => setOpen(false);

  const handleBlock = (check) => {
    handleStatus(check, selectedItem);
  };

  //for handling gallery
  const handleOpenGallery = (item) => {
    setGalleryModel(true);
    setSelectedItem(item);
  };
  const handleCloseGallery = () => {
    setGalleryModel(false);
  };
  const handleGallery = () => {};

  // handle Details
  const handleOpenDetails = (item) => {
    setSelectedItem(item);
    setOpenDetails(true);
  };
  const handleCloseDetails = () => setOpenDetails(false);

  return (
    <>
      <Typography className={styles.tableTitle}>{tableHeading}</Typography>
      {tableHeading == "All Users" ? (
        <div
          style={{ display: "flex", alignItems: "center", marginBottom: "2%" }}
        >
          <div style={{ width: "100%", position: "relative" }}>
            <TextField
              InputProps={{
                style: {
                  borderRadius: "12px",
                  border: "none",
                  backgroundColor: "white",
                  height: "56px",
                },
              }}
              className={styles.input}
              sx={{ width: "100%" }}
              onChange={(e) => setSearch(e.target.value)}
              value={search}
              placeholder={`Search User`}
            />
            {search.length > 0 ? (
              <div
                style={{
                  position: "absolute",
                  right: "15px",
                  top: "15px",
                  cursor: "pointer",
                }}
                onClick={() => setSearch("")}
              >
                <CloseIcon />
              </div>
            ) : null}
          </div>
        </div>
      ) : (
        ""
      )}
      {!loader && !tableData?.length ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <NoData />
        </div>
      ) : (
        <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                {header?.map((item, index) => {
                  return (
                    <TableCell key={index}>
                      <div
                        className={styles.grid}
                        style={
                          item === "Sr." ||
                          item === "Name" ||
                          item == "Reported to" ||
                          item == "Users"
                            ? { paddingLeft: "50px" }
                            : {}
                        }
                      >
                        {item}
                      </div>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {loader
                ? Array.from({ length: 8 }).map((_, index) => {
                    return (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        {/* <TableCell>
                    <div className={styles.grid} style={{ paddingLeft: "50px" }}>
                      <SkeletonLoader style={styles.skeletonLoader2} />
                    </div>
                  </TableCell> */}
                        <TableCell>
                          <div
                            className={styles.grid}
                            style={{ paddingLeft: "50px" }}
                          >
                            <SkeletonLoader style={styles.srSkeletonLoader} />
                          </div>
                        </TableCell>
                        <TableCell>
                          <div
                            className={styles.grid}
                            style={{ paddingLeft: "50px" }}
                          >
                            <SkeletonLoader style={styles.skeletonLoader1} />
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className={styles.grid}>
                            <SkeletonLoader style={styles.skeletonLoader} />
                          </div>
                        </TableCell>

                        {table == "user" ? (
                          <TableCell>
                            <div className={styles.grid}>
                              <SkeletonLoader style={styles.skeletonLoader2} />
                            </div>
                          </TableCell>
                        ) : (
                          ""
                        )}
                        <TableCell>
                          <div className={styles.grid}>
                            <SkeletonLoader style={styles.skeletonLoader1} />
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })
                : tableData?.map((item, index) => (
                    <TableRow
                      key={index}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      {table == "user" ||
                      table == "reported" ||
                      table == "deactivated" ||
                      table == "face" ? (
                        <TableCell>
                          <div
                            className={styles.grid}
                            style={{ paddingLeft: "50px" }}
                          >
                            {index == 9 && active != 1
                              ? active.toString() + 0
                              : active != 1
                              ? (active - 1).toString() + (index + 1)
                              : index + 1}
                          </div>
                        </TableCell>
                      ) : (
                        ""
                      )}
                      {table == "user" || table == "face" ? (
                        <TableCell>
                          <div
                            className={styles.grid}
                            style={{ paddingLeft: "50px" }}
                          >
                            {item?.avatar ? (
                              <img
                                src={PICTURE + item?.avatar || ImagePlaceholder}
                                className={styles.profilePic}
                                onError={(e) => {
                                  e.target.src = ImagePlaceholder2;
                                }}
                                alt="avatar"
                              />
                            ) : // <img
                            //   src={
                            //     item?.avatar
                            //       ? PICTURE + item?.avatar
                            //       : ImagePlaceholder
                            //   }
                            //   className={styles.profilePic}
                            //   onError={(e) => {
                            //     e.target.src = ImagePlaceholder;
                            //   }}
                            // />
                            item?.gender === "Unknown" ? (
                              <ImagePlaceholder className={styles.profilePic} />
                            ) : item?.gender === "Male" ? (
                              <MalePlaceholder className={styles.profilePic} />
                            ) : item?.gender === "Female" ? (
                              <FemalePlaceholder
                                className={styles.profilePic}
                              />
                            ) : (
                              <ImagePlaceholder className={styles.profilePic} />
                            )}
                            <span className={styles.name}>
                              {item?.name ? item?.name : "Not provided"}
                            </span>
                          </div>
                        </TableCell>
                      ) : (
                        ""
                      )}
                      {table == "reported" ? (
                        <TableCell>
                          <div
                            className={styles.grid}
                            style={{ paddingLeft: "50px" }}
                          >
                            {item?.reported_to?.avatar ? (
                              <img
                                src={PICTURE + item?.reported_to?.avatar}
                                className={styles.profilePic}
                                onError={(e) => {
                                  e.target.src = ImagePlaceholder2;
                                }}
                                alt="avatar"
                              />
                            ) : item?.reported_to?.gender == "Unknown" ? (
                              <ImagePlaceholder className={styles.profilePic} />
                            ) : item?.gender == "Male" ? (
                              <MalePlaceholder className={styles.profilePic} />
                            ) : item?.gender == "Female" ? (
                              <FemalePlaceholder
                                className={styles.profilePic}
                              />
                            ) : (
                              <ImagePlaceholder className={styles.profilePic} />
                            )}
                            <span className={styles.name}>
                              {item?.reported_to?.name
                                ? item?.reported_to?.name
                                : "Not Provided"}
                            </span>
                          </div>
                        </TableCell>
                      ) : (
                        ""
                      )}
                      {table == "user" ? (
                        <TableCell>
                          <div className={styles.grid}>
                            {item?.social_token_provider == 1
                              ? "Facebook"
                              : item?.social_token_provider == 2
                              ? "Google"
                              : item?.social_token_provider == 3
                              ? "Apple"
                              : item?.email
                              ? item?.email
                              : item?.phone_number}
                          </div>
                        </TableCell>
                      ) : (
                        ""
                      )}

                      {table == "reported" ? (
                        <TableCell>
                          <div className={styles.grid}>
                            {item?.reported_to?.avatar ? (
                              <img
                                src={PICTURE + item?.reported_by?.avatar}
                                className={styles.profilePic}
                                alt="avatar"
                                onError={(e) =>
                                  (e.target.src = ImagePlaceholder2)
                                }
                              />
                            ) : item?.reported_by?.gender === "Unknown" ? (
                              <ImagePlaceholder className={styles.profilePic} />
                            ) : item?.gender === "Male" ? (
                              <MalePlaceholder className={styles.profilePic} />
                            ) : item?.gender === "Female" ? (
                              <FemalePlaceholder
                                className={styles.profilePic}
                              />
                            ) : (
                              <ImagePlaceholder className={styles.profilePic} />
                            )}
                            <span className={styles.name}>
                              {item?.reported_by?.name
                                ? item?.reported_by?.name
                                : "Not Provided"}
                            </span>
                          </div>
                        </TableCell>
                      ) : (
                        ""
                      )}
                      {table === "user" ? (
                        <TableCell>
                          <div
                            className={styles.grid}
                            onClick={() => handleOpenDetails(item)}
                          >
                            <RemoveRedEyeIcon style={{ cursor: "pointer" }} />
                          </div>
                        </TableCell>
                      ) : (
                        ""
                      )}
                      {table == "user" ? (
                        <TableCell>
                          <div className={`${styles.icon} ${styles.grid}`}>
                            {item?.is_blocked ? (
                              <button
                                className={styles.unblockButton}
                                onClick={() => handleClickOpen(item)}
                              >
                                Unblock
                              </button>
                            ) : (
                              <button
                                className={styles.blockButton}
                                onClick={() => handleClickOpen(item)}
                              >
                                Block
                              </button>
                            )}
                          </div>
                        </TableCell>
                      ) : (
                        ""
                      )}
                      {table == "reported" ? (
                        <TableCell>
                          <div className={styles.grid}>
                            {item?.report_reason?.title}
                          </div>
                        </TableCell>
                      ) : (
                        ""
                      )}
                      {table == "face" ? (
                        <>
                          {/* <TableCell>
                        <div className={`${styles.icon} ${styles.grid}`}>
                          <CollectionsIcon onClick={() => setGalleryOpen(true)} />
                        </div>
                      </TableCell> */}
                          <TableCell>
                            <div className={`${styles.icon} ${styles.grid}`}>
                              <MoodIcon
                                onClick={() => handleOpenGallery(item)}
                              />
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className={styles.grid}>
                              <button
                                className={styles.unblockButton}
                                onClick={() => handleClickOpen(item, "Verify")}
                              >
                                Verify
                              </button>

                              <button
                                className={styles.blockButton}
                                onClick={() => handleClickOpenRejectModal(item, "Reject")}
                              >
                                Reject
                              </button>
                            </div>
                          </TableCell>
                        </>
                      ) : (
                        ""
                      )}
                      {table == "deactivated" ? (
                        <>
                          <TableCell>
                            <div
                              className={styles.grid}
                              style={{ paddingLeft: "50px" }}
                            >
                              {item?.avatar ? (
                                <img
                                  src={PICTURE + item?.avatar}
                                  className={styles.profilePic}
                                  onError={(e) =>
                                    (e.target.src = ImagePlaceholder2)
                                  }
                                  alt="avatar"
                                />
                              ) : item?.gender == "Unknown" ? (
                                <ImagePlaceholder
                                  className={styles.profilePic}
                                />
                              ) : item?.gender == "Male" ? (
                                <MalePlaceholder
                                  className={styles.profilePic}
                                />
                              ) : item?.gender == "Female" ? (
                                <FemalePlaceholder
                                  className={styles.profilePic}
                                />
                              ) : (
                                <ImagePlaceholder
                                  className={styles.profilePic}
                                />
                              )}
                              <span className={styles.name}>
                                {item?.name ? item?.name : "Not provided"}
                              </span>
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className={styles.grid}>
                              {item?.deactivate_reason?.title
                                ? item?.deactivate_reason?.title
                                : "Not Provided"}
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className={styles.grid}>
                              <button
                                className={styles.unblockButton}
                                onClick={() =>
                                  handleClickOpen(item, "Activate")
                                }
                              >
                                Activate
                              </button>
                            </div>
                          </TableCell>
                        </>
                      ) : (
                        ""
                      )}
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
          <Lightbox
            open={galleryOpen}
            close={() => setGalleryOpen(false)}
            slides={images}
            plugins={[Thumbnails]}
          />
          <ViewDetails
            open={openDetails}
            handleClose={handleCloseDetails}
            selectedItem={selectedItem}
          />
          <ConfirmationModal
            open={open}
            handleClose={handleClose}
            check={check}
            action={handleBlock}
            loader={statusLoader}
          />
          <ConfirmationModal
            open={openRejectModal}
            handleClose={handleRejectionModalClose}
            check={rejectModalCheck}
            action={handleReject}
            loader={statusLoader}
          />
          <FaceVerificationModal
            open={galleryModal}
            handleClose={handleCloseGallery}
            action={handleGallery}
            selectedItem={selectedItem}
            handleStatus={handleStatus}
          />
        </TableContainer>
      )}
    </>
  );
}

