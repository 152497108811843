import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import { Typography } from "@mui/material";
import styles from "./style.module.scss";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Feeback({ open, handleClose, selectedItem }) {
  console.log("selectedItem", selectedItem);
  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        fullWidth
      >
        <DialogContent>
          {selectedItem?.feedback.length > 0 ? (
            <>
              <Typography className={styles.title}>Feedback</Typography>

              {selectedItem?.feedback?.map((item) => {
                return (
                  <>
                    <Typography className={styles.subTitle}>
                      {item?.user_name + "'s feeback for location"}
                    </Typography>
                    <div className={styles.description}>
                      {item?.location_feedback}
                    </div>

                    <Typography className={styles.subTitle}>
                      {item?.user_name + "'s feeback for date"}
                    </Typography>
                    <div className={styles.description}>
                      {item?.user_feedback}
                    </div>
                  </>
                );
              })}
            </>
          ) : (
            <Typography className={styles.subTitle}>
              Not Provided by both
            </Typography>
          )}
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
