import React from 'react'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import styles from "./style.module.scss"

function Pagination({ lastPage, active, setActive, loader }) {
  const numbers = Array.from({ length: lastPage }, (_, index) => index + 1)

  const handleLastClick = () => {
    if (active > 1) {
      setActive(active - 1)
    }
  }

  const handleNextClick = () => {
    if (lastPage > active) {
      setActive(active + 1)
    }
  }

  return (
    <div>
      {loader ? <></> : <div className={styles.paginationContainer}>
        <div className={styles.tablePaginate} onClick={handleLastClick}>
          <KeyboardArrowLeftIcon />
        </div>
        {numbers.map((number, inx) => (
          <div
            key={inx}
            className={
              number === active ? `${styles.paginateActive} ${styles.tablePaginate}` : `${styles.tablePaginate}`
            }
            onClick={() => setActive(number)}
          >
            {number}
          </div>
        ))
        }
        <div className={styles.tablePaginate} onClick={handleNextClick}>
          <KeyboardArrowRightIcon />
        </div>
      </div >}
    </div>

  )
}

export default Pagination

