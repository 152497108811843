let Endpoints = {
  //auth
  login: "admin-login",
  logout: "admin-logout",
  changePassword: "change-password-admin",

  //terms and privacy

  getPrivacyPolicy: "privacy-policy/1",
  getTerms: "privacy-policy/0",
  updatePrivacyPolicy: "update-privacy-policy/1",
  updateTerms: "update-privacy-policy/0",

  //profile perferences
  getInterest: "interest/interests-listing",
  getGenders: "gender/genders-listing",
  getSexualOrientation: "sexual-orientation/sexual-orientations-listing",

  changeStatusInterest: "interest/update-status",
  changeStatusGender: "gender/update-status",
  changeStatusSexualOrientation: "sexual-orientation/update-status",

  createInterest: "interest/create",
  createGender: "gender/create",
  createSexualOrientation: "sexual-orientation/create",

  deleteInterest: "interest/delete/",
  deleteGender: "gender/delete/",
  deleteSexualOrientation: "sexual-orientation/delete/",

  updateInterest: "interest/update/",
  updateGender: "gender/update/",
  updateSexualOrientation: "sexual-orientation/update/",

  sexualOrientationOrderUp: "sexual-orientation/move-up",
  sexualOrientationOrderDown: "sexual-orientation/move-down",

  approveSexualOrientation: "sexual-orientation/mark-as-approved",
  disappriveSexualOrientation: "sexual-orientation/mark-as-un-approved",

  //user
  getAllUsers: "get-users",
  getAllBlockedUsers: "get-blocked-users",
  blockUser: "block-user/",
  unBlockUser: "unblock-user/",
  reportUsers: "get-reported-users",
  deactivatedUsers: "deactivated-users-list",
  activateUser: "activate-user/",

  //get Poses
  addPose: "add-verification-pose-image",
  verifyFace: "verify-face",
  rejectFaceVerificationRequest: "reject-face-verification-request",
  verifyUsersList: "user-face-verifications-list",
  getAllPoses: "get-verification-posts",
  deletePose: "delete-verification-pose-image/",
  getFaceVerificationUsers: "user-face-verifications-list",

  //dashboard data
  dashboardData: "dashboard-stats",

  //dates feedback
  datesFeedBack: "get-requests",
};

export { Endpoints };
