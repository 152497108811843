import { HTTP_Request } from "utils/interceptor";
import { BASE_URL } from "utils/constant";
import { Endpoints } from "./endpoint";
import axios from "axios";

const loginService = (obj) => {
  let formBody = new FormData();
  Object.keys(obj).forEach((key) => {
    formBody.append(key, obj[key]);
  });
  return axios.post(BASE_URL + Endpoints.login, formBody);
}

const logoutService = () => {
  return HTTP_Request.post(BASE_URL + Endpoints.logout);
}

const changePasswordService = (obj) => {
  let formBody = new FormData();
  Object.keys(obj).forEach((key) => {
    formBody.append(key, obj[key]);
  });
  return HTTP_Request.post(BASE_URL + Endpoints.changePassword, formBody);
}




export { loginService, logoutService, changePasswordService };
