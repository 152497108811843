import React, { useEffect, useState } from "react";
import styles from "./style.module.scss";
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ConfirmationModal from "shared/modals/confirmationModal"
import { useDispatch } from "react-redux";
import { logout } from "shared/redux/userSlice";
import ChangePassword from "shared/modals/changePassword";
import { logoutService } from "services/auth";
import { toastMessage } from "shared/toast";
import { useLogoutHelper } from 'utils/logoutHelper'
import Box from '@mui/material/Box';
import PieChart from "shared/components/PieChart"
import { getDashboard } from "services/user";




function Dashboard() {
  const [data, setData] = useState(null)
  const { handleLogout } = useLogoutHelper();


  const dashboardData = () => {
    getDashboard()
      .then(({ data: { data } }) => {
        setData(data);
      })
      .catch((err) => {
        toastMessage(err?.message);
        if (err?.response?.status == 401) {
          handleLogout()
        }
      })
  }

  useEffect(() => {
    dashboardData();
  }, [])


  return (
    <div>
      <div className={styles.dashboardCard}>
        <div>
          <div className={styles.label}>Welcome, Admin!</div>
          <div className={styles.subLabel}>Let’s get this started.</div>
        </div>
        <div className={styles.gird}>
          <BasicMenu />
        </div>
      </div>

      <PieChart stats={data} />
    </div>
  );
}

export default Dashboard;



function BasicMenu() {
  const dispatch = useDispatch();
  const { handleLogout } = useLogoutHelper()
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [loader, setLoader] = useState(false);
  const [logoutOpen, setLogoutOpen] = useState(false);
  const [changePasswordOpen, setChangePasswordOpen] = useState(false);
  const handleOpenLogout = () => setLogoutOpen(true);
  const handleCloseLogout = () => setLogoutOpen(false);
  const handleOpenChangePassowrd = () => {
    setChangePasswordOpen(true)
  };
  const handleCloseChangePassowrd = () => setChangePasswordOpen(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogoutUser = () => {
    setLoader(true);
    logoutService()
      .then(({ data: { message } }) => {
        toastMessage(message)
        setLoader(false);
        dispatch(logout());
      })
      .catch((err) => {
        toastMessage(err?.response?.data?.message, "error");
        setLoader(false);
        if (err?.response?.status == 401) {
          handleLogout()
        }
      })
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        className={styles.dropdown}
      >
        Menu
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleOpenChangePassowrd} className={styles.dropDownItem}>Change Password</MenuItem>
        <MenuItem onClick={handleOpenLogout} className={styles.dropDownItem}>Logout</MenuItem>
        <ConfirmationModal open={logoutOpen} handleClose={handleCloseLogout} check={"Logout"} action={handleLogoutUser} loader={loader} />
        <ChangePassword open={changePasswordOpen} handleClose={handleCloseChangePassowrd} closeMenu={handleClose} />
      </Menu>
    </div>
  );
}




const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);

// function DataCard({ data, heading, Icon }) {
//   return (
//     <Card sx={{ position: "relative" }}>
//       <CardContent>
//         <Icon sx={{
//           position: "absolute",
//           top: "16px",
//           right: "16ppx",
//           cursor: "pointer",
//         }} />
//         <Typography variant="h5" component="div" className={styles.cardHeading}>
//           {heading}
//         </Typography>
//         <Typography className={styles.cardData}>
//           {data}
//         </Typography>
//       </CardContent>
//     </Card>
//   );
// }
