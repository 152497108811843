import React, { useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import CustomButton from "shared/components/customButton";
import styles from "./style.module.scss";
import CustomCancelButton from "shared/components/customCancelButton";
import CustomInsideButton from "shared/components/customButtonInside"
import CloseIcon from "@mui/icons-material/Close";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({ open, handleClose, check, action, loader }) {

  const handleAction = () => {
    action(check);
  }

  useEffect(() => {
    if (!loader) {
      handleClose();
    }
  }, [loader])

  return (
    <div>
      <Dialog open={open} TransitionComponent={Transition} keepMounted onClose={handleClose} aria-describedby="alert-dialog-slide-description" className={styles.modalContainer}>
        <DialogContent>
          <CloseIcon
            sx={{
              position: "absolute",
              top: "24px",
              right: "24px",
              cursor: "pointer",
            }}
            onClick={handleClose}
          />
          <div className={styles.label}>{check} ?</div>
          <div className={styles.subLabel}>Are you sure, you want to {check.toLowerCase()}?</div>
          <div style={{ display: "flex" }}>
            <div style={{ padding: "4px" }}>
              <CustomButton handleClick={handleAction} text={"Yes"} loader={loader} />
            </div>
            <div style={{ padding: "4px" }}>
              {/* <CustomCancelButton handleClick={handleClose} text={"No"} /> */}
              <CustomInsideButton handleClick={handleClose} text={"No"} />
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
