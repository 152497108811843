import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import CustomButton from "shared/components/customButton";
import styles from "./style.module.scss";
import CustomInsideButton from "shared/components/customButtonInside"
import CloseIcon from "@mui/icons-material/Close";
import { toastMessage } from "shared/toast";
import { Box, TextField, Typography } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { changePasswordService } from "services/auth";
import { useLogoutHelper } from 'utils/logoutHelper'


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({ open, handleClose, closeMenu }) {
  const [loader, setLoader] = useState(false);
  const [oldPassword, setOldPassword] = useState('')
  const [password, setPassword] = useState('')
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const { handleLogout } = useLogoutHelper()


  const onClose = () => {
    setOldPassword('')
    setPassword('')
    setConfirmNewPassword('')
    handleClose();
    closeMenu();
  }

  const handleSubmit = () => {

    if (oldPassword === '' || confirmNewPassword === '' || password === '') {
      toastMessage(oldPassword.length === 0 ? 'Please enter old password.' : password.length === 0 ? "Please enter new password." : confirmNewPassword.length === 0 ? 'Please enter confirm password.' : "Please enter all enteries.", 'error')
    } else {
      let obj = {
        old_password: oldPassword,
        password: confirmNewPassword,
        password_confirmation: password,
      }
      setLoader(true);
      changePasswordService(obj)
        .then(({ data }) => {
          setLoader(false);
          if (data?.status) {
            onClose();
            toastMessage(data?.message);
            closeMenu();
          } else {
            toastMessage(data?.message, "error");
          }
        })
        .catch((err) => {
          toastMessage(err?.response?.data?.message, "error");
          setLoader(false);
          if (err?.response?.status == 401) {
            handleLogout()
          }
        })
    }
  }

  return (
    <div>
      <Dialog open={open} TransitionComponent={Transition} keepMounted onClose={onClose} aria-describedby="alert-dialog-slide-description" className="modalContainer">
        <DialogContent>
          <CloseIcon
            sx={{
              position: "absolute",
              top: "24px",
              right: "24px",
              cursor: "pointer",
            }}
            onClick={onClose}
          />
          <div className={styles.label}>Change Password</div>
          <div>
            <TextFieldTitle text={'Old Password:'} />
            <TextFieldInput
              bool={true}
              setState={setOldPassword}
              state={oldPassword}
              handleSubmit={handleSubmit}
            />
            <TextFieldTitle text={'New Password:'} />

            <TextFieldInput
              bool={true}
              setState={setPassword}
              state={password}
              handleSubmit={handleSubmit}
            />
            <TextFieldTitle text={'Confirm New Password:'} />
            <TextFieldInput
              bool={true}
              setState={setConfirmNewPassword}
              state={confirmNewPassword}
              handleSubmit={handleSubmit}
            />
          </div>
          <div style={{ display: "flex" }}>
            <div style={{ padding: "4px", width: "100%" }}>
              <CustomButton handleClick={handleSubmit} text={"Change password"} loader={loader} />
            </div>
            <div style={{ padding: "4px", width: "100%" }}>
              <CustomInsideButton handleClick={onClose} text={"Cancel"} />
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div >
  );
}

const TextFieldInput = ({ bool, setState, state, handleSubmit }) => {
  const [showPassword, setShowPassword] = useState(bool)
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSubmit()
    }
  }
  return (
    <>
      <TextField
        InputProps={{
          style: {
            borderRadius: '12px',
            border: 'none',
            backgroundColor: '#F8F8F9',
            height: '56px',
          },
          endAdornment: (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                height: '100%',
              }}
              onClick={togglePasswordVisibility}
            >
              {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </Box>
          ),
        }}
        sx={{ width: '100%' }}
        type={!showPassword ? 'text' : 'password'}
        value={state}
        onChange={(e) => setState(e.target.value)}
        onKeyDown={handleKeyDown}
        className={styles.input}
      />
    </>
  )
}

const TextFieldTitle = ({ text }) => {
  return (
    <>
      <Typography
        sx={{
          fontStyle: 'normal',
          fontWeight: '400',
          fontSize: '14px',
          lineHeight: '24px',
          color: '#11142D',
          marginTop: '20px',
          marginBottom: '5px',
        }}
      >
        {text}
      </Typography>
    </>
  )
}

