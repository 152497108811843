import React, { useState, useRef, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import styles from "./style.module.scss";
import CircularProgress from '@mui/material/CircularProgress';
import ConfirmationModal from "shared/modals/confirmationModal";
import EmojiSelector from "shared/components/customEmojiSelector";
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';
import { NoData } from 'assets';
import { approveSexual, disapproveSexual, sexualOrderDown, sexualOrderUp } from 'services/profileServices';
import { TextField, Typography } from '@mui/material';
import { toastMessage } from 'shared/toast';
import Loader from 'shared/loader/loader';
import SkeletonLoader from 'shared/skeletonLoader';


//icons
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';



export default function CustomPreferenceTable({
  header,
  tableData,
  heading,
  editChange,
  onDelete,
  editLoader,
  deleteLoader,
  handleAddition,
  addLoader,
  handleStatus,
  loader,
  active,
  handleGetOrder
}) {
  const [selectedItem, setSelectedItem] = useState(null);
  const [editBool, setEditBool] = useState(false);
  const [changeText, setChangeText] = useState("");
  const [open, setOpen] = useState(false);
  const [text, setText] = useState("");
  const [showEmoji, setShowEmoji] = useState(false);
  const inputRef = useRef();
  const emojiRef = useRef();
  const [anchorEl, setAnchorEl] = useState(null);




  const handleClickOpen = (item) => {
    setOpen(true);
    setSelectedItem(item)
  }

  const handleClose = () => {
    setOpen(false)
  }


  const handleEdit = (item) => {
    setEditBool(true);
    setSelectedItem(item);
    setChangeText(heading === "Gender" ? item?.gender : heading === "Interests" ? item?.text : item?.title);

  };

  const handleEditKeyDown = (e) => {
    // e.stopPropagation();
    if (e.key == "Enter") {
      handleEditChange();
    }
  }

  const handleEditChange = (e) => {
    if (!changeText.length) {
      toastMessage("Enter something", "error");
    } else if (detectEmojis(changeText)) {
      if (changeText.length > 21) {
        console.log(changeText.length)
        toastMessage(`${heading} cannot be greater than 20 characters`, "error");
      } else {
        editChange(changeText, selectedItem);
        setText("");
      }
    } else if (changeText.length >= 21) {
      toastMessage(`${heading} cannot be greater than 20 characters`, "error");
    } else {
      if (heading == "Interests" && !detectEmojis(changeText)) {
        toastMessage(`Please add emoji`, "error");
      } else {
        editChange(changeText, selectedItem);
        setText("");
      }

    }
    // editChange(changeText, selectedItem);
    setEditBool(false)
  }


  const handleDelete = () => {
    onDelete(selectedItem);
  }

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleAdd();
    }
  };

  const handleAdd = () => {
    if (!text.length) {
      toastMessage("Enter something", "error");
    } else if (detectEmojis(text)) {
      if (text.length > 21) {
        console.log("we come here")
        console.log(text.length)
        toastMessage(`${heading} cannot be greater than 20 characters`, "error");
      } else {
        handleAddition(text);
        setText("");
      }
    } else if (text.length >= 21) {
      toastMessage(`${heading} cannot be greater than 20 characters`, "error");
    } else {
      if (heading == "Interests" && !detectEmojis(text)) {
        toastMessage(`Please add emoji`, "error");
      } else {
        handleAddition(text);
        setText("");
      }

    }
  }

  const handleSelectEmoji = (e) => {
    if (detectEmojis(text)) {
      toastMessage("Emoji is already added", "error")
    } else {
      setText(e.native + text);
    }
  }

  function detectEmojis(inputString) {
    const emojiPattern = /[\u{1F300}-\u{1F6FF}]/gu;
    const emojisFound = inputString.match(emojiPattern);

    return emojisFound;
  }

  const handleEditEmojiChange = (e) => {
    if (detectEmojis(changeText)) {
      toastMessage("Emoji is already added", "error")
    } else {
      setChangeText(e.native + changeText);
    }
  }

  const handleChangeStatus = (item) => {
    handleStatus(item)
  }

  var firstTime = null;
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current?.contains(event.target)) {

        // if (!firstTime) {
        //   setEditBool(true);
        //   firstTime = true
        // } else {
        //   setEditBool(false);
        // }
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [editBool]);

  const handleOrder = (check, item) => {
    if (check == "up") {
      sexualOrderUp({ id: item?.id })
        .then(({ data: { message } }) => {
          toastMessage(message);
          handleGetOrder();
        })
        .catch(() => {

        })

    } else {
      sexualOrderDown({ id: item?.id })
        .then(({ data: { message } }) => {
          toastMessage(message);
          handleGetOrder();
        })
        .catch(() => {

        })
    }
  }

  const handleApprove = (item) => {

    if (!item?.is_admin_approved) {
      approveSexual({ id: item?.id })
        .then(({ data: { message } }) => {
          handleGetOrder();
          toastMessage(message);
        })
        .catch((err) => {

        })
    } else {
      disapproveSexual({ id: item?.id })
        .then(({ data: { message } }) => {
          handleGetOrder();
          toastMessage(message);
        })
        .catch((err) => {

        })
    }
  }


  return (
    <>
      <Typography className={styles.tableTitle}>
        {heading}
      </Typography>
      <div className={styles.subHeading}>Want to add more ?</div>
      <div style={{ display: "flex", alignItems: "center", marginBottom: "2%" }}>
        <div style={{ width: "100%", position: "relative" }}>
          <TextField
            InputProps={{
              style: {
                borderRadius: "12px",
                border: "none",
                backgroundColor: "white",
                height: "56px",
                marginRight: "30px"
              },
            }}
            className={styles.input}
            sx={{ width: "100%" }}
            onChange={(e) => setText(e.target.value)}
            value={text}
            onKeyDown={handleKeyDown}
            placeholder={`Add ${heading}`}
          />
          {heading === "Interests" ? <div><EmojiSelector handleSelectEmoji={handleSelectEmoji} check={true} /></div> : ""}

        </div>

        <button className={styles.addButton} variant="contained" onClick={() => handleAdd()}>
          {addLoader ? <Loader /> : "Add"}
        </button>
      </div>
      {!loader && !tableData?.length ?
        <div style={{ display: "flex", justifyContent: "center" }}>
          <NoData />
        </div> : <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                {header?.map((item) => {
                  return (
                    <TableCell key={item}>
                      <div className={styles.grid}>
                        {loader ? <SkeletonLoader style={styles.skeletonLoader} /> : item}
                      </div>
                    </TableCell>
                  )
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {loader ? Array.from({ length: 8 }).map((_, index) => {
                return (
                  <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell>
                      <div className={styles.grid}>
                        <SkeletonLoader style={styles.skeletonLoader} />
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className={styles.grid}>
                        <SkeletonLoader style={styles.skeletonLoader} />
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className={styles.grid}>
                        <SkeletonLoader style={styles.skeletonLoader1} />
                        <SkeletonLoader style={styles.skeletonLoader1} />
                        <SkeletonLoader style={styles.skeletonLoader1} />
                      </div>
                    </TableCell>
                    {heading == "Sexual Orientation" ? <TableCell>
                      <div className={styles.grid}>
                        <SkeletonLoader style={styles.skeletonLoaderOrder} />
                      </div>
                    </TableCell> : null}
                  </TableRow>)
              }) : tableData?.map((item, index, arr) => (
                <TableRow
                  key={item?.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell>
                    <div className={styles.grid}>
                      {index == 9 && active != 1 ? active.toString() + 0 : active != 1 ? (active - 1).toString() + (index + 1) : index + 1}
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className={styles.grid}>
                      {editBool && selectedItem?.id == item?.id ?
                        <div style={{ position: "relative" }}>
                          <input ref={inputRef} value={changeText} onChange={(e) => setChangeText(e.target.value)} onKeyDown={handleEditKeyDown} className={heading == "Interests" ? styles.editInterest : styles.inputEdit} />
                          {heading === "Interests" ? <div ref={emojiRef}> <EmojiSelector handleSelectEmoji={handleEditEmojiChange} edit={true} /></div> : ""}
                          <button className={styles.editAddButton} onClick={handleEditChange}>Add</button>
                        </div>
                        :
                        <div className={styles.title}>{heading == "Gender" ? item?.gender : heading === "Interests" ? item?.text : item?.title} {editLoader && selectedItem?.id == item?.id ? <CircularProgress sx={{ color: "black" }} className={styles.circular} /> : ""}</div>
                      }
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className={styles.grid}>
                      {heading == "Sexual Orientation" ?
                        <button className={!item?.is_admin_approved == "1" ? styles.approveButton : styles.disapproveButton} onClick={() => handleApprove(item)}>
                          {!item?.is_admin_approved == "1" ?
                            <Tooltip title="Approve">
                              <DoneIcon />
                            </Tooltip> :
                            <Tooltip title="Disapprove">
                              <ClearIcon />
                            </Tooltip>}
                        </button> : ""}
                      <button className={styles.editButton} onClick={() => handleEdit(item)}>
                        <Tooltip title="Edit">
                          <EditIcon />
                        </Tooltip>
                      </button>
                      <button className={styles.deleteButton} onClick={() => handleClickOpen(item)}>
                        <Tooltip title="Delete">
                          <DeleteIcon />
                        </Tooltip>
                      </button>
                      <Tooltip title="Change Status">
                        <Switch defaultChecked={item?.is_admin_verified === 1 ? true : item?.is_active === 1 ? true : false} size="small" onChange={() => handleChangeStatus(item)} />
                      </Tooltip>
                    </div>
                  </TableCell>

                  {heading == "Sexual Orientation" ? <TableCell>
                    <div className={`${styles.grid} ${styles.order}`}>
                      <div onClick={() => handleOrder("up", item)}>{<KeyboardArrowUpIcon sx={{ cursor: "pointer" }} />}</div>
                      <div onClick={() => handleOrder("down", item)}>
                        {<KeyboardArrowDownIcon sx={{ cursor: "pointer" }} />}
                      </div>
                    </div>
                  </TableCell> : null}

                </TableRow>
              ))}
            </TableBody>
          </Table>
          <ConfirmationModal
            open={open}
            handleClose={handleClose}
            check={"Delete"}
            action={handleDelete}
            loader={deleteLoader}
          />
        </TableContainer >}
    </>
  );
}
