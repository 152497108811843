import React, { useState } from 'react'
import Editor from 'shared/components/editor'
import { getTermsService, updateTermsService } from 'services/terms';

function Terms() {
  const [text, setText] = useState('');


  return (
    <>
      <Editor
        text={text}
        setText={setText}
        getAPI={getTermsService}
        updateAPI={updateTermsService}
        type="terms"
      />
    </>
  )
}

export default Terms
