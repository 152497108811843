
import React, { useEffect } from 'react';
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import styles from "./style.module.scss"

function EmojiSelector({ handleSelectEmoji, edit, check }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // .MuiMenu-paper {
  //   right: 20px !important;
  //   left: initial !important;
  // }

  const selection = (e) => {
    handleSelectEmoji(e);
    setAnchorEl(null);

  }




  return (
    <div style={!edit ? { position: "absolute", top: "15px", right: "45px" } : { position: "absolute", top: "7px", right: "5px" }}>
      <SentimentVerySatisfiedIcon onClick={handleClick} style={{ cursor: "pointer" }} />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        PaperProps={{
          className: !check ? "noEdit" : "emojiPosition"
        }}
        className={styles.noPadding}
      >
        <Picker previewPosition="none" data={data} onEmojiSelect={selection} />
      </Menu>
    </div >
  );
}

export default EmojiSelector;