import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import styles from "./style.module.scss";
import { useMediaQuery } from "@mui/material";
import ConfirmationModal from "shared/modals/confirmationModal";
import { useLocation, useNavigate } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import { Logo } from "assets";
import { useDispatch } from "react-redux";
import { logout } from "shared/redux/userSlice";
import RateReviewIcon from "@mui/icons-material/RateReview";

//icons
import WindowIcon from "@mui/icons-material/Window";
import GroupIcon from "@mui/icons-material/Group";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import FaceIcon from "@mui/icons-material/Face";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import WcIcon from "@mui/icons-material/Wc";
import ReportIcon from "@mui/icons-material/Report";
import NoAccountsIcon from "@mui/icons-material/NoAccounts";
import InterestsIcon from "@mui/icons-material/Interests";
import MaleIcon from "@mui/icons-material/Male";
import PersonPinCircleIcon from "@mui/icons-material/PersonPinCircle";
import AssignmentIcon from "@mui/icons-material/Assignment";
import InfoIcon from "@mui/icons-material/Info";
import PrivacyTipIcon from "@mui/icons-material/PrivacyTip";
import VerifiedIcon from "@mui/icons-material/Verified";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";

const drawerWidth = 320;

let sideBarList = [
  {
    name: "Dashboard",
    path: "/",
    icon: <WindowIcon />,
  },

  {
    name: "Users data",
    path: "/User",
    icon: <AccountBoxIcon />,
    expand: false,
    children: [
      {
        name: "All Users",
        path: "/users",
        icon: <GroupIcon />,
      },
      {
        name: "Blocked Users",
        path: "/blocked-users",
        icon: <NoAccountsIcon />,
      },
      {
        name: "Reported Users",
        path: "/reported-users",
        icon: <ReportIcon />,
      },
      {
        name: "Deactivated Users",
        path: "/deactivated-users",
        icon: <SentimentVeryDissatisfiedIcon />,
      },
    ],
  },
  // {
  //   name: 'Face Verification',
  //   path: '/face-verification',
  //   icon: <FaceIcon />,
  // },
  {
    name: "Dates Feedback",
    path: "/dates-feedback",
    icon: <RateReviewIcon />,
  },
  {
    name: "Verification",
    path: "/face",
    icon: <FaceIcon />,
    expand: false,
    children: [
      {
        name: "Face Verification",
        path: "/face-verification",
        icon: <VerifiedIcon />,
      },
      {
        name: "Poses",
        path: "/poses",
        icon: <EmojiPeopleIcon />,
      },
    ],
  },
  {
    name: "Profile preferences",
    path: "/profile",
    icon: <PersonPinCircleIcon />,
    expand: false,
    children: [
      // {
      //   name: "Gender",
      //   path: "/genders",
      //   icon: <WcIcon />,
      // },
      {
        name: "Interests",
        path: "/interests",
        icon: <InterestsIcon />,
      },
      {
        name: "Sexual Orientation",
        path: "/sexual-orientation",
        icon: <MaleIcon />,
      },
    ],
  },
  {
    name: "Add Info",
    path: "/info",
    icon: <InfoIcon />,
    expand: false,
    children: [
      {
        name: "Terms and Conditions",
        path: "/terms-and-conditions",
        icon: <AssignmentIcon />,
      },
      {
        name: "Privacy Policy",
        path: "/privacy-policy",
        icon: <PrivacyTipIcon />,
      },
    ],
  },
];

function ResponsiveDrawer(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [sideBarItems, setSideBarItems] = useState(sideBarList);
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const isWidth600 = useMediaQuery("(max-width:600px)");
  const isWidth900 = useMediaQuery("(max-width:900px)");
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const handleLogout = () => {
    setLoader(true);
    setTimeout(() => {
      setLoader(false);
      dispatch(logout());
    }, 1000);
  };

  const handleClickOpen = () => {
    setOpen(true);
    setMobileOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDrawerToggle = (props) => {
    setMobileOpen(!mobileOpen);
  };

  const DashItem = (item, inx, event) => {
    event.stopPropagation();
    if (item?.children) {
      let temp = [...sideBarItems];
      temp[inx]["expand"] = !item.expand;
      setSideBarItems(temp);
    } else {
      navigate(item.path);
      setMobileOpen(false);
    }
  };

  useEffect(() => {
    const updatedListItems = sideBarItems.map((item) => {
      if (location.pathname.includes(item.path)) {
        return {
          ...item,
          expand: true,
        };
      } else if (item.children) {
        const updatedChildren = item.children.map((childItem) => {
          if (location.pathname.includes(childItem.path)) {
            return {
              ...childItem,
              expand: true,
            };
          }
          return childItem;
        });
        return {
          ...item,
          expand: updatedChildren.some((childItem) =>
            location.pathname.includes(childItem.path)
          ),
          children: updatedChildren,
        };
      }
      return item;
    });
    setSideBarItems(updatedListItems);
  }, [location]);

  const drawer = (
    <div className={styles.drawerContainer}>
      <Toolbar sx={{ display: "flex", justifyContent: "center" }}>
        <Logo
          style={{ width: "150px", height: "100px", cursor: "pointer" }}
          onClick={() => navigate("/")}
        />
      </Toolbar>
      <List>
        {sideBarItems?.map((item, inx) => (
          <ListItem
            key={inx}
            disablePadding
            sx={{ display: "block" }}
            onClick={(event) => DashItem(item, inx, event)}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: "space-between",
                "&:hover": {
                  background: "transparent",
                },
              }}
            >
              <div
                className={
                  item.path === props.path ? styles.active : styles.sideBarItem
                }
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: "auto",
                    justifyContent: "center",
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText className={styles.menuText}>
                  {item.name}
                </ListItemText>
              </div>
              {item.children && item.expand ? (
                <KeyboardArrowUpIcon style={{ color: "black" }} />
              ) : item.children && !item.expand ? (
                <KeyboardArrowDownIcon style={{ color: "black" }} />
              ) : (
                ""
              )}
            </ListItemButton>

            {item.children && item.expand && (
              <List>
                {item.children.map((childItem, childIndex) => (
                  <ListItem
                    key={childIndex}
                    disablePadding
                    sx={{ display: "block" }}
                    onClick={(event) => DashItem(childItem, childIndex, event)}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        px: 4.5,
                      }}
                    >
                      <div
                        className={
                          childItem.path === props.path
                            ? styles.active
                            : styles.sideBarItem
                        }
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: "auto",
                            justifyContent: "center",
                          }}
                        >
                          {childItem.icon}
                        </ListItemIcon>
                        <ListItemText className={styles.menuText}>
                          {childItem.name}
                        </ListItemText>
                      </div>
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            )}
          </ListItem>
        ))}
      </List>
      {/* <ListItem disablePadding sx={{ display: "block" }}>
        <ListItemButton
          sx={{
            minHeight: 48,
            justifyContent: "flex-start",
            "&:hover": {
              background: "transparent",
            },
          }}
        >
          <div className={`${styles.sideBarItem} ${styles.sideBarLogout} `} onClick={handleClickOpen}>
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: "auto",
                justifyContent: "center",
                color: "red",
                fill: "red",
              }}
            >
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText className={styles.menuText}>Logout</ListItemText>
          </div>
        </ListItemButton>
      </ListItem> */}
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
        className={styles.backgroundToolbar}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, color: "#253989", display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              border: "none",
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        className={styles.main}
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        {/* {isWidth600 ? <Toolbar /> : ""} */}
        <props.component />
      </Box>
      <ConfirmationModal
        open={open}
        handleClose={handleClose}
        check={"Logout"}
        action={handleLogout}
        loader={loader}
      />
    </Box>
  );
}

export default ResponsiveDrawer;
