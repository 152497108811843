import { HTTP_Request } from "utils/interceptor";
import { BASE_URL } from "utils/constant";
import { Endpoints } from "./endpoint";

const getAllUsersService = (active, search) => {
  return HTTP_Request.get(BASE_URL + Endpoints.getAllUsers + `?page=${active}&search=${search}`);
}

const getAllBlockedUsersService = (active) => {
  return HTTP_Request.get(BASE_URL + Endpoints.getAllBlockedUsers + `?page=${active}`);
}


const getAllReportedUserService = (active) => {
  return HTTP_Request.get(BASE_URL + Endpoints.reportUsers + `?page=${active}`);
}

const blockUserService = (id) => {
  return HTTP_Request.post(BASE_URL + Endpoints.blockUser + id);
}

const unBlockUserService = (id) => {
  return HTTP_Request.post(BASE_URL + Endpoints.unBlockUser + id);
}

const activateUserService = (id) => {
  return HTTP_Request.post(BASE_URL + Endpoints.activateUser + id);
}

const getDeactivated = (active) => {
  return HTTP_Request.get(BASE_URL + Endpoints.deactivatedUsers + `?page=${active}`);
}

const getDashboard = () => {
  return HTTP_Request.get(BASE_URL + Endpoints.dashboardData);

}

export { getAllUsersService, getAllBlockedUsersService, blockUserService, unBlockUserService, getAllReportedUserService, activateUserService, getDeactivated, getDashboard };
