import { Pagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import { blockUserService, getAllUsersService, unBlockUserService } from "services/user";
import CustomTable from "shared/components/customTable";
// import Pagination from "shared/components/pagination";
import { toastMessage } from "shared/toast";
import { useLogoutHelper } from 'utils/logoutHelper'
import styles from "./style.module.scss";
let header = ["Sr.", "Name", "Platform", "Details", "Action"];

function Users() {
  const [statusLoader, setStatusLoader] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const [loader, setLoader] = useState(true);
  const [lastPage, setLastPage] = useState(0);
  const [active, setActive] = useState(1);
  const [search, setSearch] = useState("");
  const [count, setCount] = useState(null)
  const { handleLogout } = useLogoutHelper();


  const getAllUsers = () => {
    setLoader(true)
    getAllUsersService(active, search)
      .then(({ data: { data: { users } } }) => {
        setLastPage(users?.last_page)
        setAllUsers(users?.data)
        setLoader(false)
        setCount(users?.total)
      })
      .catch((err) => {
        setLoader(false);
        toastMessage(err?.message);
        if (err?.response?.status == 401) {
          handleLogout()
        }
      })
  }

  useEffect(() => {
    getAllUsers()
  }, [active, search])

  const handleStatus = (check, item) => {
    setStatusLoader(true);
    if (check == "Block") {
      blockUserService(item?.id)
        .then(({ data }) => {
          setStatusLoader(false);
          setUsers();
        })
        .catch((err) => {

        })
    } else if (check == "Unblock") {
      unBlockUserService(item?.id)
        .then(({ data }) => {
          setStatusLoader(false);
          setUsers();
        })
        .catch((err) => {

        })
    }

    function setUsers() {
      let temp = [...allUsers];
      let index = temp.findIndex((i) => i?.id == item?.id);
      if (index > -1) {
        temp[index].is_blocked = !temp[index].is_blocked
        toastMessage(item?.name ? `${item?.name} ${check == "Block" ? "blocked" : "unblocked"}` : `${check == "Block" ? "Blocked" : "Unblocked"}`)
        setAllUsers(temp);
      }
    }
  }

  const handleChangePage = (event, newPage) => {
    setActive(newPage);
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  return (
    <>
      <CustomTable
        header={header}
        tableData={allUsers}
        tableHeading={"All Users"}
        table={"user"}
        handleStatus={handleStatus}
        statusLoader={statusLoader}
        active={active}
        loader={loader}
        setSearch={setSearch}
        search={search}
      />
      {/* {allUsers.length ? <Pagination lastPage={lastPage} active={active} setActive={setActive} loader={loader} /> : ""} */}
      {Math.ceil(count / 10) > 1 ?
        <div className={styles.paginationContainer}>
          <Pagination
            count={Math.ceil(count / 10)}
            page={active}
            onChange={handleChangePage}
            variant="outlined"
            shape="rounded"
            sx={{
              '& .MuiPaginationItem-root': {
                height: '50px',
                width: "50px",
                fontSize: "1rem"
              },
              '& .Mui-selected': {
                backgroundColor: '#435ebe !important',
                color: 'white',
                border: "none"
              },
            }}
          />
        </div>
        : null}
    </>
  )
}

export default Users;
