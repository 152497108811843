import React, { useEffect, useState } from "react";
import styles from "./style.module.scss";
import { Button, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ConfirmationModal from "shared/modals/confirmationModal";
import { useLogoutHelper } from "utils/logoutHelper";
import {
  addPoseService,
  deletePoseService,
  getAllPosesService,
} from "services/verification";
import { PICTURE } from "utils/constant";
import { toastMessage } from "shared/toast";
import SkeletonLoader from "shared/skeletonLoader";

function AddPose() {
  const [allImages, setAllImages] = useState([]);
  const [open, setOpen] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [selectedPicture, setSelectedPicture] = useState(null);
  const [loader, setLoader] = useState(false);
  const { handleLogout } = useLogoutHelper();

  const handleClickOpen = (item) => {
    setOpen(true);
    setSelectedPicture(item);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedPicture(null);
  };

  const getAllPoses = (check) => {
    if (!check) {
      setLoader(true);
    }
    getAllPosesService()
      .then(({ data: { data } }) => {
        setLoader(false);

        let images = [];
        data?.map((i) => {
          let obj = i;
          obj["url"] = PICTURE + i.image;
          images.push(obj);
        });
        setAllImages(images);
      })
      .catch((err) => {
        setLoader(false);
        toastMessage(err?.message);
        if (err?.response?.status == 401) {
          handleLogout();
        }
      });
  };

  useEffect(() => {
    getAllPoses();
  }, []);

  const handleDelete = (item) => {
    setDeleteLoader(true);
    deletePoseService(selectedPicture?.id)
      .then(({ data: { message } }) => {
        toastMessage(message);
        getAllPoses("check");
        setDeleteLoader(false);
      })
      .catch(() => {
        setDeleteLoader(false);
      });
  };

  return (
    <div>
      <Typography className={styles.tableTitle}>Poses</Typography>
      <div className={styles.subHeading}>
        Want to add more? Click on plus icon.
      </div>

      <div className={styles.imageContainer}>
        {loader ? (
          <>
            <SkeletonLoader style={styles.skeletonLoader} />
            <SkeletonLoader style={styles.skeletonLoader} />
            <SkeletonLoader style={styles.skeletonLoader} />
          </>
        ) : (
          allImages?.map((picture) => {
            return (
              <div style={{ position: "relative" }}>
                <img src={picture.url} className={styles.preview} />

                <HighlightOffIcon
                  sx={{
                    position: "absolute",
                    top: "10px",
                    right: "20px",
                    cursor: "pointer",
                    color: "white",
                  }}
                  onClick={() => handleClickOpen(picture)}
                />
              </div>
            );
          })
        )}

        <ImageUploader getAllPoses={getAllPoses} />
      </div>
      <ConfirmationModal
        open={open}
        handleClose={handleClose}
        check={"Delete"}
        action={handleDelete}
        loader={deleteLoader}
      />
    </div>
  );
}

export default AddPose;

const ImageUploader = ({ getAllPoses }) => {
  const [preview, setPreview] = useState(null);
  const [rawImage, setRawImage] = useState(null);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size / (1024 * 1024) > 5) {
        toastMessage("Please select image less than 5 mbs", "error");
      } else {
        const reader = new FileReader();
        reader.onloadend = () => {
          setPreview(reader.result);
          setRawImage(file);
        };
        reader.readAsDataURL(file);

        addPoseService({ image: file })
          .then(({ data: { message } }) => {
            toastMessage(message);
            getAllPoses("check");
          })
          .catch((err) => {
            toastMessage(err.response?.data?.message, "error");
          });
      }
    } else {
      setPreview(null);
      setRawImage(null);
    }
  };

  return (
    <div>
      <input
        type="file"
        id="imageInput"
        accept="image/*"
        style={{ display: "none" }}
        onChange={handleImageChange}
      />
      <label htmlFor="imageInput">
        <div className={styles.singleContainer}>
          <AddIcon style={{ color: "#435ebe" }} />
        </div>
      </label>
    </div>
  );
};
