import styles from "./style.module.scss";

function SkeletonLoader({ style }) {
  return (
    <>
      <div
        className={`${styles.skeletonLoader} ${style}`}
      >
      </div >
    </>
  );
}

export default SkeletonLoader;
