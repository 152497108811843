import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Container from "@mui/material/Container";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import styles from "./style.module.scss";
import { useDispatch } from "react-redux";
import { Logo } from "assets";
import CustomButton from "shared/components/customButton";
import { toastMessage } from "shared/toast";
import { login } from "shared/redux/userSlice";
import { loginService } from "services/auth";

export default function Login() {
  const [loader, setLoader] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();

  const handleSubmit = () => {
    if (email === "" || password === "") {
      toastMessage("Please enter all fields", "error");
    } else {
      setLoader(true);

      loginService({ email, password, })
        .then((data) => {
          if (data?.data?.status) {
            setLoader(false);
            let obj = {
              user: data?.data?.data,
              isLoggedin: true,
              token: data?.data?.data?.token
            };
            dispatch(login(obj));
            toastMessage(data?.data?.message)
          } else {
            toastMessage(data?.data?.message, "error");
            setLoader(false);
          }

        })
        .catch((err) => {
          toastMessage(err, "error")
          setLoader(false)
        })
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  const changeTitle = () => {
    document.title = "Login | HUK'D";
  };
  useEffect(() => {
    changeTitle();
  }, []);

  return (
    <div className={styles.background}>
      <Box height="100vh" display="flex" alignItems="center" justifyContent="center">
        <Card sx={{ minWidth: "594px" }} className={styles.cardContainer}>
          <CardContent sx={{ padding: "0px" }}>
            <Container
              sx={{
                width: "90%",
                paddingTop: "36px",
                paddingBottom: "36px",
              }}
              className="containerLogin"
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Logo style={{ width: "150px", height: "100px" }} />
              </div>

              <Typography color="text.secondary" gutterBottom className={styles.label}>
                Welcome!
              </Typography>

              <Typography
                sx={{
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "24px",
                  color: "#11142D",
                  marginTop: "20px",
                  marginBottom: "5px",
                }}
              >
                Email
              </Typography>

              <TextField
                InputProps={{
                  style: {
                    borderRadius: "12px",
                    border: "none",
                    backgroundColor: "#F8F8F9",
                    height: "56px",
                    fontWeight: "600"
                  },
                }}
                className={styles.input}
                sx={{ width: "100%" }}
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                onKeyDown={handleKeyDown}
              />
              <Typography
                sx={{
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "24px",
                  color: "#11142D",
                  marginTop: "20px",
                  marginBottom: "5px",
                }}
              >
                Password
              </Typography>

              <TextField
                InputProps={{
                  style: {
                    borderRadius: "12px",
                    border: "none",
                    backgroundColor: "#F8F8F9",
                    height: "56px",
                    fontWeight: "600"
                  },
                  endAdornment: (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                        height: "100%",
                      }}
                      onClick={togglePasswordVisibility}
                    >
                      {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </Box>
                  ),
                }}
                sx={{ width: "100%" }}
                type={showPassword ? "text" : "password"}
                onChange={(e) => setPassword(e.target.value)}
                onKeyDown={handleKeyDown}
                className={styles.input}
              />
              <CustomButton handleClick={handleSubmit} text={"Login"} loader={loader} />
            </Container>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
}
