import React from 'react'
import SkeletonLoader from '..'
import styles from "./style.module.scss";

function index() {


  return (
    <div>
      <div className={styles.container}>
        <div className={styles.toolbar} >
          <SkeletonLoader style={styles.type1} />
          <SkeletonLoader style={styles.type1} />
          <SkeletonLoader style={styles.type2} />
          <SkeletonLoader style={styles.type1} />
          <SkeletonLoader style={styles.type1} />
          <SkeletonLoader style={styles.type1} />
          <div style={{ marginLeft: "20px", display: "flex" }}>
            <SkeletonLoader style={styles.type1} />
            <SkeletonLoader style={styles.type1} />
            <SkeletonLoader style={styles.type1} />
          </div>

        </div>
      </div>
      <SkeletonLoader style={styles.button} />
    </div>
  )
}

export default index