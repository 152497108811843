import { Pagination } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { getInterestServices, changeInterestStatus, createInterestService, deleteInterestService, updateInterestService } from 'services/profileServices';
import CustomPreferenceTable from 'shared/components/customPreferenceTable';
// import Pagination from 'shared/components/pagination';
import { toastMessage } from 'shared/toast';
import { useLogoutHelper } from 'utils/logoutHelper';
import styles from "./style.module.scss";


function Interests() {
  const [allInterests, setAllInterests] = useState([]);
  const [editLoader, setEditLoader] = useState(false);
  const [loader, setLoader] = useState(false)
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [addLoader, setAddLoader] = useState(false);
  const { handleLogout } = useLogoutHelper();
  const [lastPage, setLastPage] = useState(0);
  const [active, setActive] = useState(1);
  const [count, setCount] = useState(null)
  let header = ["Sr", "Title", "Actions"];



  const handleEdit = (changeText, selectedItem) => {
    setEditLoader(true)
    updateInterestService({ text: changeText }, selectedItem?.id)
      .then(({ data: { data: { isUpdated }, message } }) => {
        toastMessage(message)
        if (isUpdated) {
          const updatedInterests = allInterests.map((interest) => {
            if (interest.id === selectedItem.id) {
              return { ...interest, text: changeText };
            }
            return interest;
          });
          setAllInterests(updatedInterests);
          setEditLoader(false)
        }
      })
      .catch((err) => {
        setEditLoader(false)
        toastMessage(err?.response?.data?.message, "error");
        if (err?.response?.status == 401) {
          handleLogout()
        }
      })
  }

  const handleDelete = (item) => {
    setDeleteLoader(true)
    deleteInterestService(item?.id)
      .then(({ data: { data: { isDeleted }, message } }) => {
        if (isDeleted == 1) {
          toastMessage(message)
          const updatedInterests = allInterests.filter((interest) => interest.id !== item.id);
          setAllInterests(updatedInterests);
          setDeleteLoader(false)
        }
      })
      .catch((err) => {
        setDeleteLoader(false)
        toastMessage(err?.response?.data?.message, "error");
        if (err?.response?.status == 401) {
          handleLogout()
        }
      })
  }

  const handleAdd = (text) => {
    setAddLoader(true);
    createInterestService({ text: text })
      .then(({ data: { data: { interest }, message } }) => {
        toastMessage(message)
        setAddLoader(false);
        setAllInterests([interest, ...allInterests])
      })
      .catch((err) => {
        setAddLoader(false);
        toastMessage(err?.response?.data?.message, "error");
        if (err?.response?.status == 401) {
          handleLogout()
        }
      })
  }

  const getAllInterest = () => {
    setLoader(true)
    getInterestServices(active)
      .then(({ data: { data: { interests } } }) => {
        setLoader(false);
        setAllInterests(interests?.data);
        setLastPage(interests.last_page);
        setCount(interests?.total)
      })
      .catch((err) => {
        setLoader(false)
        toastMessage(err?.response?.data?.message, "error");
        if (err?.response?.status == 401) {
          handleLogout()
        }
      })
  }

  useEffect(() => {
    getAllInterest();
  }, [active])

  const handleStatus = (item) => {
    let temp = [...allInterests];
    let index = temp.findIndex((i) => i?.id == item?.id);
    if (index > -1) {
      changeInterestStatus({ id: item?.id, status: temp[index]?.is_admin_verified === 1 ? 0 : 1 })
        .then(({ data: { data, message } }) => {
          temp[index]['is_admin_verified'] = (temp[index]?.is_admin_verified === 1 ? 0 : 1);
          toastMessage(message)
        })
        .catch((err) => {
          toastMessage(err?.response?.data?.message, "error");
          if (err?.response?.status == 401) {
            handleLogout()
          }
        })
    }
    setAllInterests(temp);
  };

  const handleChangePage = (event, newPage) => {
    setActive(newPage);
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };


  return (
    <div>
      <CustomPreferenceTable
        loader={loader}
        header={header}
        tableData={allInterests}
        editChange={handleEdit}
        onDelete={handleDelete}
        heading={"Interests"}
        editLoader={editLoader}
        deleteLoader={deleteLoader}
        handleAddition={handleAdd}
        addLoader={addLoader}
        handleStatus={handleStatus}
        active={active}
      />
      {/* {allInterests.length ? <Pagination lastPage={lastPage} active={active} setActive={setActive} loader={loader} /> : ""} */}
      {Math.ceil(count / 10) > 1 ?
        <div className={styles.paginationContainer}>
          <Pagination
            count={Math.ceil(count / 10)}
            page={active}
            onChange={handleChangePage}
            variant="outlined"
            shape="rounded"
            sx={{
              '& .MuiPaginationItem-root': {
                height: '50px',
                width: "50px",
                fontSize: "1rem"
              },
              '& .Mui-selected': {
                backgroundColor: '#435ebe !important',
                color: 'white',
                border: "none"
              },
            }}
          />
        </div>
        : null}
    </div>
  )
}

export default Interests



