import Dashboard from "pages/dashboard";
import Login from "pages/auth";
import Users from "pages/users";
import FaceVerification from "pages/faceVerification";
import Genders from "pages/gender";
import BlockedUsers from "pages/blockedUsers";
import ReportedUsers from "pages/reportedUsers";
import SexualOrientation from "pages/sexualOrientation";
import Interests from "pages/interests";
import Terms from "pages/terms";
import PrivacyPolicy from "pages/privacyPolicy";
import AddPose from "pages/pose";
import CloseAccount from "pages/closeAccount";
import DeactivatedUsers from "pages/deactivatedUsers";
import DatesFeedback from "pages/datesFeedback";

const privateRoutes = [
  {
    component: Dashboard,
    path: "/",
    title: "Dashboard",
    name: "Dashboard",
  },
  {
    component: Users,
    path: "/users",
    title: "Users",
    name: "Users",
  },
  {
    component: FaceVerification,
    path: "/face-verification",
    title: "Face Verification",
    name: "Face Verification",
  },
  {
    component: Genders,
    path: "/genders",
    title: "Genders",
    name: "Genders",
  },
  {
    component: BlockedUsers,
    path: "/blocked-users",
    title: "Blocked Users",
    name: "Blocked Users",
  },
  {
    component: ReportedUsers,
    path: "/reported-users",
    title: "Reported Users",
    name: "Reported Users",
  },
  {
    component: SexualOrientation,
    path: "/sexual-orientation",
    title: "Sexual Orientation",
    name: "Sexual Orientation",
  },
  {
    component: Interests,
    path: "/interests",
    title: "Interests",
    name: "Interests",
  },
  {
    component: Terms,
    path: "/terms-and-conditions",
    title: "Terms and Conditions",
    name: "Terms and Conditions",
  },
  {
    component: PrivacyPolicy,
    path: "/privacy-policy",
    title: "Privacy Policy",
    name: "Privacy Policy",
  },
  {
    component: AddPose,
    path: "/poses",
    title: "Poses",
    name: "Poses",
  },
  {
    component: DeactivatedUsers,
    path: "/deactivated-users",
    title: "Deactivated Users",
    name: "Deactivated Users",
  },
  {
    component: DatesFeedback,
    path: "/dates-feedback",
    title: "Dates Feedback",
    name: "Dates Feedback",
  },
];

const publicRoutes = [
  {
    component: Login,
    path: "/",
    title: "Login",
  },
];

export { publicRoutes, privateRoutes };
