import React from "react";
import Lottie from "react-lottie";
import styles from "./style.module.scss"

function Animation({ Pic, Message }) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Pic,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div className={styles.loading}>
      <Lottie
        isClickToPauseDisabled
        options={defaultOptions}
        isStopped={false}
        isPaused={false}
        height={"5%"}
        width={"30px"}
      />
      {Message && (
        <h3 className="text-secondary text-center mt-4">{Message}</h3>
      )}
    </div>
  );
}

export default Animation;
