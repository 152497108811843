import { HTTP_Request } from "utils/interceptor";
import { BASE_URL } from "utils/constant";
import { Endpoints } from "./endpoint";

const getInterestServices = (active) => {
  return HTTP_Request.get(BASE_URL + Endpoints.getInterest + `?page=${active}`);
}

const getGenderService = (active) => {
  return HTTP_Request.get(BASE_URL + Endpoints.getGenders + `?page=${active}`);
}

const getSexualOrientationService = (active) => {
  return HTTP_Request.get(BASE_URL + Endpoints.getSexualOrientation + `?page=${active}`);
}

const changeInterestStatus = (obj) => {
  let formBody = new FormData();
  Object.keys(obj).forEach((key) => {
    formBody.append(key, obj[key]);
  });
  return HTTP_Request.post(BASE_URL + Endpoints.changeStatusInterest, formBody);
}

const changeGenderStatus = (obj) => {
  let formBody = new FormData();
  Object.keys(obj).forEach((key) => {
    formBody.append(key, obj[key]);
  });
  return HTTP_Request.post(BASE_URL + Endpoints.changeStatusGender, formBody);
}

const changeSexualOrientationStatus = (obj) => {
  let formBody = new FormData();
  Object.keys(obj).forEach((key) => {
    formBody.append(key, obj[key]);
  });
  return HTTP_Request.post(BASE_URL + Endpoints.changeStatusSexualOrientation, formBody);
}

const createInterestService = (obj) => {
  let formBody = new FormData();
  Object.keys(obj).forEach((key) => {
    formBody.append(key, obj[key]);
  });
  return HTTP_Request.post(BASE_URL + Endpoints.createInterest, formBody);
}

const createGenderService = (obj) => {
  let formBody = new FormData();
  Object.keys(obj).forEach((key) => {
    formBody.append(key, obj[key]);
  });
  return HTTP_Request.post(BASE_URL + Endpoints.createGender, formBody);
}

const createSexualOrientationService = (obj) => {
  let formBody = new FormData();
  Object.keys(obj).forEach((key) => {
    formBody.append(key, obj[key]);
  });
  return HTTP_Request.post(BASE_URL + Endpoints.createSexualOrientation, formBody);
}

const updateInterestService = (obj, id) => {
  let formBody = new FormData();
  Object.keys(obj).forEach((key) => {
    formBody.append(key, obj[key]);
  });
  return HTTP_Request.post(BASE_URL + Endpoints.updateInterest + id, formBody);
}

const updateGenderService = (obj, id) => {
  let formBody = new FormData();
  Object.keys(obj).forEach((key) => {
    formBody.append(key, obj[key]);
  });
  return HTTP_Request.post(BASE_URL + Endpoints.updateGender + id, formBody);
}

const updateSexualOrientationService = (obj, id) => {
  let formBody = new FormData();
  Object.keys(obj).forEach((key) => {
    formBody.append(key, obj[key]);
  });
  return HTTP_Request.post(BASE_URL + Endpoints.updateSexualOrientation + id, formBody);
}

const deleteInterestService = (id) => {
  return HTTP_Request.delete(BASE_URL + Endpoints.deleteInterest + id)
}

const deleteGenderService = (id) => {
  return HTTP_Request.delete(BASE_URL + Endpoints.deleteGender + id)
}

const deleteSexualOrientationService = (id) => {
  return HTTP_Request.delete(BASE_URL + Endpoints.deleteSexualOrientation + id)
}

const sexualOrderUp = (obj) => {
  return HTTP_Request.post(BASE_URL + Endpoints.sexualOrientationOrderUp, obj);
}

const sexualOrderDown = (obj) => {
  return HTTP_Request.post(BASE_URL + Endpoints.sexualOrientationOrderDown, obj);
}

const approveSexual = (obj) => {
  return HTTP_Request.post(BASE_URL + Endpoints.approveSexualOrientation, obj);
}

const disapproveSexual = (obj) => {
  return HTTP_Request.post(BASE_URL + Endpoints.disappriveSexualOrientation, obj);
}





export {
  getInterestServices,
  getGenderService,
  getSexualOrientationService,
  changeInterestStatus,
  changeGenderStatus,
  changeSexualOrientationStatus,
  createInterestService,
  createGenderService,
  createSexualOrientationService,
  updateInterestService,
  updateGenderService,
  updateSexualOrientationService,
  deleteInterestService,
  deleteGenderService,
  deleteSexualOrientationService,
  sexualOrderUp,
  sexualOrderDown,
  approveSexual,
  disapproveSexual
};
